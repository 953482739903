<template>
  <v-card style="padding: 14px; margin: 10px" elevation="2">
    <div>
      <v-row>
        <v-col cols="12" sm="12" md="12" style="display: flex">
          <span style="padding-top: 10px; padding-right: 10px">Сделка</span>
          <v-text-field
            @click="openCRMDialog"
            @change="openCRMDialog"
            :value="calculateValue.deal.title"
            label
            placeholder
            dense
            solo
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="5" md="5" style="display: flex">
          <span style="padding-top: 10px; padding-right: 10px"
            >Тип клиента</span
          >
          <!-- <v-text-field label="Сlient type" placeholder ></v-text-field> -->
          <v-select
            :items="DEFAULT_FIELDS.typeClients"
            v-model="filterTemplate"
            dense
            solo
          ></v-select>
        </v-col>
        <v-col
          cols="12"
          sm="5"
          offset="2"
          md="5"
          style="display: flex; flex-direction: column"
        >
          <div style="display: flex">
            <span style="padding-top: 10px; padding-right: 10px"
              >Тип договора</span
            >
            <!-- <v-text-field label="Type of contract" placeholder dense solo></v-text-field> -->
            <div>
              <v-select
                :items="templateSelect"
                :value="templateId"
                item-text="name"
                item-value="id"
                style="max-width: 300px"
                dense
                solo
                @change="changeTemplate"
              ></v-select>
              <div
                v-if="!onSpecialCase && templateId"
                style="display: flex; flex-direction: column"
              >
                <div
                  style="
                    font-size: 0.7;
                    display: flex;
                    justify-content: space-between;
                  "
                >
                  <span style="display: block"
                    >НДС на контрактную стоимость</span
                  >
                  <b style="display: block"
                    >{{ calculateValue.template.VATContract }}%</b
                  >
                </div>
                <div
                  style="
                    font-size: 0.7;
                    display: flex;
                    justify-content: space-between;
                  "
                >
                  <span style="display: block">НДС на вознаграждение</span>
                  <b style="display: block"
                    >{{ calculateValue.template.VATRevard }}%</b
                  >
                </div>
                <div
                  style="
                    font-size: 0.7;
                    display: flex;
                    justify-content: space-between;
                  "
                >
                  <span style="display: block">НДС на страховку</span>
                  <b style="display: block"
                    >{{ calculateValue.template.VATInsurance }}%</b
                  >
                </div>
                <div
                  style="
                    font-size: 0.7;
                    display: flex;
                    justify-content: space-between;
                  "
                >
                  <span style="display: block">НДС на доп. расходы</span>
                  <b style="display: block"
                    >{{ calculateValue.template.VATMore }}%</b
                  >
                </div>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>

      <span
        style="
          display: block;
          margin-top: 15px;
          font-size: 1.2em;
          color: rgb(25 118 210);
          font-weight: 500;
        "
        >Основные сведения</span
      >
      <v-divider style="border-color: rgb(25 118 210)"></v-divider>
      <v-row style="margin-top: 10px">
        <v-col
          cols="12"
          sm="12"
          md="12"
          style="display: flex; justify-content: flex-start"
        >
          <span style="padding-top: 10px; padding-right: 10px">Цена</span>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="calculateValue.price"
                type="number"
                label
                placeholder
                dense
                solo
                style="max-width: 300px; margin-right: 30px"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
              <v-checkbox
                :label="НДС"
                color="info"
                v-model="calculateValue.priceNDS"
                class="mr-5"
                style="margin-top: 6px"
              >
                <template v-slot:label>
                  <div>НДС</div>
                </template>
              </v-checkbox>
            </template>
            <span v-if="calculateValue.priceNDS">Вводить цену c НДС</span>
            <span v-else>Вводить цену без НДС</span>
          </v-tooltip>
          <div style="display: flex">
            <v-checkbox
              v-for="item in currencyValues"
              :label="item.name"
              color="info"
              :value="item.name"
              :key="item.name"
              hide-details
              class="mr-5"
              style="margin-top: 6px"
              v-model="currencyCalc"
            >
              <template v-slot:label>
                <div>
                  <div v-if="item.more">
                    <v-hover v-slot="{ hover }">
                      <div>
                        <span>{{ item.name }}</span>
                        <v-icon
                          v-if="hover"
                          color="red"
                          small
                          style="margin-bottom: 3px"
                          @click="deleteMore('currencyValues', item)"
                          >mdi-close</v-icon
                        >
                        <div
                          v-else
                          style="
                            width: 16px;
                            height: 10px;
                            display: inline-block;
                          "
                        ></div>
                      </div>
                    </v-hover>
                  </div>
                  <div v-else>{{ item.name }}</div>
                </div>
              </template>
            </v-checkbox>
          </div>
          <v-dialog transition="dialog-bottom-transition" max-width="600">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                style="height: 31px; width: 31px; margin-top: 7px"
                v-bind="attrs"
                v-on="on"
                @click="() => updateDataDialog('currencyValues')"
                >mdi-plus</v-icon
              >
            </template>
            <template v-slot:default="dialog">
              <v-card>
                <v-card-title class="text-h5"></v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col
                      cols="6"
                      style="display: flex; justify-content: flex-start"
                    >
                      <div style="padding-top: 10px; padding-right: 10px">
                        Валюта
                      </div>
                      <v-select
                        :value="valuesDialogsMore.currencyValues.name"
                        :items="currencyValuesMore"
                        item-text="name"
                        item-value="name"
                        solo
                        @change="
                          (val) => changeCurrencyMore('currencyValues', val)
                        "
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="green darken-1"
                    text
                    @click="
                      () => {
                        dialog.value = false;
                        clearFieldsDialog('currencyValues');
                      }
                    "
                    >Отмена</v-btn
                  >
                  <v-btn
                    color="green darken-1"
                    text
                    @click="
                      () => {
                        dialog.value = !saveFieldsDialog('currencyValues');
                      }
                    "
                    >Добавить</v-btn
                  >
                </v-card-actions>
              </v-card>
            </template>
          </v-dialog>
        </v-col>
      </v-row>
      <div v-show="!onSpecialCase">
        <v-row style="margin-top: 10px">
          <v-col cols="12" sm="12" md="12" style="display: flex">
            <span style="padding-top: 10px; padding-right: 10px"
              >Участие клиента</span
            >
            <div style="display: flex">
              <v-checkbox
                v-for="item in involvementValues"
                :label="item.name"
                color="info"
                class="mr-5"
                style="margin-top: 6px"
                :value="item.name"
                :key="item.name"
                hide-details
                v-model="calculateValue['involvementValues']"
              >
                <template v-slot:label>
                  <div>
                    <div v-if="item.more">
                      <v-hover v-slot="{ hover }">
                        <div>
                          <span>{{ item.name }}</span>
                          <v-icon
                            v-if="hover"
                            color="red"
                            small
                            style="margin-bottom: 3px"
                            @click="deleteMore('involvementValues', item)"
                            >mdi-close</v-icon
                          >
                          <div
                            v-else
                            style="
                              width: 16px;
                              height: 10px;
                              display: inline-block;
                            "
                          ></div>
                        </div>
                      </v-hover>
                    </div>
                    <div v-else>{{ item.name }}</div>
                  </div>
                </template>
              </v-checkbox>
            </div>
            <v-dialog transition="dialog-bottom-transition" max-width="600">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  style="height: 31px; width: 31px; margin-top: 7px"
                  v-bind="attrs"
                  v-on="on"
                  >mdi-plus</v-icon
                >
              </template>
              <template v-slot:default="dialog">
                <v-card>
                  <v-card-title class="text-h5"></v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col
                        cols="6"
                        style="display: flex; justify-content: flex-start"
                      >
                        <div style="padding-top: 10px; padding-right: 10px">
                          Процент
                        </div>
                        <v-text-field
                          label
                          solo
                          :append-icon="
                            valuesDialogsMore.involvementValues.type == 'money'
                              ? 'mdi-cash'
                              : 'mdi-percent-outline'
                          "
                          v-model="valuesDialogsMore.involvementValues.value"
                          @click:append="changeTypeInvolvement()"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="green darken-1"
                      text
                      @click="
                        () => {
                          dialog.value = false;
                          clearFieldsDialog('involvementValues');
                        }
                      "
                      >Отмена</v-btn
                    >
                    <v-btn
                      color="green darken-1"
                      text
                      @click="
                        () => {
                          dialog.value = !saveFieldsDialog('involvementValues');
                        }
                      "
                      >Добавить</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </template>
            </v-dialog>
          </v-col>
        </v-row>
        <v-row style="margin-top: 10px">
          <v-col cols="12" sm="12" md="12" style="display: flex">
            <span
              style="
                dispay: block;
                width: 250px;
                padding-top: 0px;
                padding-right: 10px;
              "
              >Выкупная стоимость, вносимая после последнего платежа</span
            >
            <div style="display: flex">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="calculateValue.redemption"
                    label
                    placeholder
                    solo
                    dense
                    type="number"
                    append-icon="mdi-percent-outline"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <span>Изменить при необходимости</span>
              </v-tooltip>
            </div>
          </v-col>
        </v-row>
        <v-row style="margin-top: 10px">
          <v-col cols="12" sm="12" md="12" style="display: flex">
            <span style="dispay: block; width: 250px; padding-top: 10px"
              >Совокупная процентная ставка</span
            >
            <!-- <div style="display: flex">
              <v-text-field
                v-model="calculateValue.interestRate"
                label
                placeholder
                solo
                dense
                type="number"
                append-icon="mdi-percent-outline"
              ></v-text-field>
            </div>-->
            <div style="display: flex">
              <v-dialog transition="dialog-bottom-transition" max-width="600">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    style="
                      height: 31px;
                      width: 31px;
                      margin-top: 7px;
                      margin-left: 7px;
                    "
                    v-bind="attrs"
                    v-on="on"
                    :disabled="listReward.length == 0"
                    >mdi-table-refresh</v-icon
                  >
                </template>
                <template v-slot:default="dialog">
                  <v-card>
                    <v-card-title class="text-h5"></v-card-title>
                    <v-card-text>
                      <v-row
                        v-for="item in listReward"
                        :key="item.name + '-' + returnValueDialogReward(item)"
                      >
                        <div style="padding-top: 10px; padding-right: 10px">
                          {{ item.name }}
                        </div>
                        <v-text-field
                          append-icon="mdi-percent-outline"
                          solo
                          dense
                          style="width: 130px"
                          :value="item.value"
                          @change="(val) => changeReward(item, val)"
                        ></v-text-field>
                      </v-row>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="green darken-1"
                        text
                        @click="
                          () => {
                            dialog.value = false;
                            cancelReward();
                          }
                        "
                        >Отмена</v-btn
                      >
                      <v-btn
                        color="green darken-1"
                        text
                        @click="
                          () => {
                            dialog.value = !saveReward();
                          }
                        "
                        >Добавить</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </template>
              </v-dialog>
            </div>
          </v-col>
        </v-row>
        <v-row style="margin-top: 0px">
          <v-col cols="7" style="display: flex; flex-direction: column">
            <span
              style="
                padding-top: 10px;
                padding-right: 10px;
                color: rgb(25 118 210);
                font-weight: 500;
              "
              >Валюта договора</span
            >
            <div style="display: flex">
              <v-checkbox
                v-for="item in contractCurrencyValues"
                :label="item.name"
                color="info"
                class="mr-5"
                style="margin-top: 6px"
                :value="item.name"
                :key="item.name"
                hide-details
                v-model="calculateValue.contractCurrencyValues"
              >
                <template v-slot:label>
                  <div>
                    <div v-if="item.more">
                      <v-hover v-slot="{ hover }">
                        <div>
                          <span>{{ item.name }}</span>
                          <v-icon
                            v-if="hover"
                            color="red"
                            small
                            style="margin-bottom: 3px"
                            @click="deleteMore('contractCurrencyValues', item)"
                            >mdi-close</v-icon
                          >
                          <div
                            v-else
                            style="
                              width: 16px;
                              height: 10px;
                              display: inline-block;
                            "
                          ></div>
                        </div>
                      </v-hover>
                    </div>
                    <div v-else>{{ item.name }}</div>
                  </div>
                </template>
              </v-checkbox>
              <v-dialog transition="dialog-bottom-transition" max-width="600">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    style="height: 31px; width: 31px; margin-top: 7px"
                    v-bind="attrs"
                    v-on="on"
                    @click="() => updateDataDialog('contractCurrencyValues')"
                    >mdi-plus</v-icon
                  >
                </template>
                <template v-slot:default="dialog">
                  <v-card>
                    <v-card-title class="text-h5"></v-card-title>
                    <v-card-text>
                      <v-row>
                        <v-col
                          cols="6"
                          style="display: flex; justify-content: flex-start"
                        >
                          <div style="padding-top: 10px; padding-right: 10px">
                            Валюта
                          </div>
                          <v-select
                            :value="
                              valuesDialogsMore.contractCurrencyValues.name
                            "
                            :items="contractCurrencyValuesMore"
                            item-text="name"
                            item-value="name"
                            solo
                            @change="
                              (val) =>
                                changeCurrencyMore(
                                  'contractCurrencyValues',
                                  val
                                )
                            "
                          ></v-select>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="green darken-1"
                        text
                        @click="
                          () => {
                            dialog.value = false;
                            clearFieldsDialog('contractCurrencyValues');
                          }
                        "
                        >Отмена</v-btn
                      >
                      <v-btn
                        color="green darken-1"
                        text
                        @click="
                          () => {
                            dialog.value = !saveFieldsDialog(
                              'contractCurrencyValues'
                            );
                          }
                        "
                        >Добавить</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </template>
              </v-dialog>
            </div>
          </v-col>
          <v-col
            cols="4"
            offset="1"
            style="display: flex; flex-direction: column"
          >
            <span
              style="
                padding-top: 10px;
                padding-right: 10px;
                width: 250px;
                padding-top: 10px;
                padding-right: 10px;
                color: rgb(25 118 210);
                font-weight: 500;
              "
              >Увеличенный курс оплаты</span
            >
            <div style="display: flex">
              <v-text-field
                v-model="calculateValue.paymentRate"
                label
                placeholder
                solo
                dense
                type="number"
                append-icon="mdi-percent-outline"
              ></v-text-field>
            </div>
          </v-col>
        </v-row>
        <v-row style="margin-top: 0px">
          <v-col
            cols="12"
            sm="12"
            md="12"
            style="display: flex; flex-direction: column"
          >
            <span
              style="
                padding-top: 10px;
                padding-right: 10px;
                color: rgb(25 118 210);
                font-weight: 500;
              "
              >Срок лизинга</span
            >
            <div style="display: flex; flex-direction: row; flex-wrap: wrap">
              <v-checkbox
                v-for="item in leaseTermValues"
                :label="item.name"
                color="info"
                class="mr-5"
                style="margin-top: 12px; width: 30%"
                :value="item.name"
                :key="item.name"
                hide-details
                v-model="calculateValue['leaseTermValues']"
                :disabled="checkDisabledLeaseTermValues(item)"
              >
                <template v-slot:label>
                  <div>
                    <div v-if="item.more">
                      <v-hover v-slot="{ hover }">
                        <div>
                          <span>{{ item.name }}</span>
                          <v-icon
                            v-if="hover"
                            color="red"
                            small
                            style="margin-bottom: 3px"
                            @click="deleteMore('leaseTermValues', item)"
                            >mdi-close</v-icon
                          >
                          <div
                            v-else
                            style="
                              width: 16px;
                              height: 10px;
                              display: inline-block;
                            "
                          ></div>
                        </div>
                      </v-hover>
                    </div>
                    <div v-else>{{ item.name }}</div>
                  </div>
                </template>
              </v-checkbox>
              <v-dialog transition="dialog-bottom-transition" max-width="600">
                <template v-slot:activator="{ on, attrs }">
                  <div style="width: 30%; height: 45px">
                    <v-icon
                      style="
                        height: 31px;
                        width: 31px;
                        margin-top: 7px;
                        margin-left: -4px;
                      "
                      v-bind="attrs"
                      v-on="on"
                      >mdi-plus</v-icon
                    >
                  </div>
                </template>
                <template v-slot:default="dialog">
                  <v-card>
                    <v-card-title class="text-h5"></v-card-title>
                    <v-card-text>
                      <v-row>
                        <v-col
                          cols="6"
                          style="display: flex; justify-content: flex-start"
                        >
                          <div style="padding-top: 10px; padding-right: 10px">
                            Месяцев
                          </div>
                          <v-text-field
                            solo
                            append
                            v-model="valuesDialogsMore.leaseTermValues.value"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="green darken-1"
                        text
                        @click="
                          () => {
                            dialog.value = false;
                            clearFieldsDialog('leaseTermValues');
                          }
                        "
                        >Отмена</v-btn
                      >
                      <v-btn
                        color="green darken-1"
                        text
                        @click="
                          () => {
                            dialog.value = !saveFieldsDialog('leaseTermValues');
                          }
                        "
                        >Добавить</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </template>
              </v-dialog>
            </div>
          </v-col>
        </v-row>
        <span
          style="
            display: block;
            margin-top: 15px;
            font-size: 1.2em;
            color: rgb(25 118 210);
            font-weight: 500;
          "
          >Погашение участия лизинговой компании</span
        >
        <v-divider style="border-color: rgb(25 118 210)"></v-divider>
        <v-row style="margin-top: 10px">
          <v-col cols="12" sm="12" md="12" style="display: flex">
            <span style="padding-top: 10px; padding-right: 10px"
              >Тип гашения</span
            >
            <div style="display: flex">
              <v-checkbox
                v-for="item in repaymentTypeValues"
                :label="item.name"
                color="info"
                class="mr-5"
                style="margin-top: 6px"
                :value="item.name"
                :key="item.name"
                hide-details
                :disabled="checkDisabledFields(item)"
                v-model="calculateValue['repaymentTypeValues']"
              >
                <template v-slot:label>
                  <div>
                    <div v-if="item.more">
                      <v-hover v-slot="{ hover }">
                        <div>
                          <span>{{ item.name }}</span>
                          <v-icon
                            v-if="hover"
                            color="red"
                            small
                            style="margin-bottom: 3px"
                            @click="deleteMore('repaymentTypeValues', item)"
                            >mdi-close</v-icon
                          >
                          <div
                            v-else
                            style="
                              width: 16px;
                              height: 10px;
                              display: inline-block;
                            "
                          ></div>
                        </div>
                      </v-hover>
                    </div>
                    <div v-else>{{ item.name }}</div>
                  </div>
                </template>
              </v-checkbox>

              <v-dialog transition="dialog-bottom-transition" max-width="400">
                <template v-slot:activator="{ on, attrs }">
                  <!-- <v-btn style="margin-left: -9px; margin-top: 3px;" fab dark small color="indigo" v-bind="attrs" v-on="on">
              <v-icon dark>mdi-plus</v-icon>
                  </v-btn>-->
                  <v-icon
                    style="height: 31px; width: 31px; margin-top: 7px"
                    v-bind="attrs"
                    v-on="on"
                    :disabled="calculateValue.leaseTermValues.length != 1"
                    @click="
                      () => {
                        setFormatDataDialog('repaymentTypeValues');
                      }
                    "
                    >mdi-plus</v-icon
                  >
                </template>
                <template v-slot:default="dialog">
                  <v-card>
                    <v-card-title class="text-h5"></v-card-title>
                    <v-card-text>
                      <v-row style="margin-bottom: -30px">
                        <v-col
                          cols="3"
                          style="display: flex; justify-content: flex-start"
                        >
                          <div style="margin-top: 10px; display: block">
                            Название
                          </div>
                        </v-col>
                        <v-col
                          cols="8"
                          style="display: flex; justify-content: flex-start"
                        >
                          <v-text-field
                            label
                            solo
                            append
                            v-model="valuesDialogsMore.repaymentTypeValues.name"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row
                        v-for="(i, index) in valuesDialogsMore
                          .repaymentTypeValues.value"
                        :key="index"
                        style="margin-bottom: -40px"
                      >
                        <v-col cols="2" offset="1" style="padding-left: 0">
                          <span
                            style="
                              margin-top: 10px;
                              display: block;
                              font-size: 1.5em;
                              color: rgb(25 118 210);
                            "
                            >{{ index + 1 }}</span
                          >
                        </v-col>
                        <v-col
                          cols="5"
                          style="display: flex; justify-content: flex-start"
                        >
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                label
                                solo
                                append
                                type="number"
                                append-icon="mdi-percent-outline"
                                v-model="
                                  valuesDialogsMore.repaymentTypeValues.value[
                                    index
                                  ]
                                "
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <span>{{
                              sumDialogField("repaymentTypeValues", index)
                            }}</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="green darken-1"
                        text
                        @click="
                          () => {
                            dialog.value = false;
                            clearFieldsDialog('repaymentTypeValues');
                          }
                        "
                        >Отмена</v-btn
                      >
                      <v-btn
                        color="green darken-1"
                        text
                        @click="
                          () => {
                            dialog.value = !saveFieldsDialog(
                              'repaymentTypeValues'
                            );
                          }
                        "
                        >Добавить</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </template>
              </v-dialog>
            </div>
          </v-col>
        </v-row>
        <span
          style="
            display: block;
            margin-top: 15px;
            font-size: 1.2em;
            color: rgb(25 118 210);
            font-weight: 500;
          "
          >Страховка</span
        >
        <v-divider style="border-color: rgb(25 118 210)"></v-divider>
        <v-row style="margin-top: 10px; padding-bottom: 0">
          <v-col cols="5" style="display: flex">
            <span style="padding-top: 10px; padding-right: 10px">Тариф</span>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="calculateValue.insuranceRate"
                  label
                  placeholder
                  solo
                  dense
                  type="number"
                  append-icon="mdi-percent-outline"
                  style="max-width: 150px"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <span>% от стоимости предмета договора</span>
            </v-tooltip>
            <div style="display: flex"></div>
          </v-col>
        </v-row>
        <v-row style="margin-top: 0px">
          <v-col
            cols="12"
            sm="12"
            md="12"
            style="display: flex; padding-top: 0"
          >
            <span style="padding-top: 10px; padding-right: 10px"
              >Тип страховки</span
            >
            <div style="display: flex">
              <v-checkbox
                v-for="item in insuranceTypeValues"
                :label="item.name"
                color="info"
                class="mr-5"
                style="margin-top: 6px"
                :value="item.name"
                :key="item.name"
                hide-details
                :disabled="checkDisabledFields(item)"
                v-model="calculateValue.insuranceTypeValues"
              >
                <template v-slot:label>
                  <div>
                    <div v-if="item.more">
                      <v-hover v-slot="{ hover }">
                        <div>
                          <span>{{ item.name }}</span>
                          <v-icon
                            v-if="hover"
                            color="red"
                            small
                            style="margin-bottom: 3px"
                            @click="deleteMore('insuranceTypeValues', item)"
                            >mdi-close</v-icon
                          >
                          <div
                            v-else
                            style="
                              width: 16px;
                              height: 10px;
                              display: inline-block;
                            "
                          ></div>
                        </div>
                      </v-hover>
                    </div>
                    <div v-else>{{ item.name }}</div>
                  </div>
                </template>
              </v-checkbox>
              <v-dialog transition="dialog-bottom-transition" max-width="400">
                <template v-slot:activator="{ on, attrs }">
                  <!-- <v-btn style="margin-left: -9px; margin-top: 3px;" fab dark small color="indigo" v-bind="attrs" v-on="on">
              <v-icon dark>mdi-plus</v-icon>
                  </v-btn>-->
                  <v-icon
                    style="height: 31px; width: 31px; margin-top: 7px"
                    v-bind="attrs"
                    v-on="on"
                    :disabled="calculateValue.leaseTermValues.length != 1"
                    @click="
                      () => {
                        setFormatDataDialog('insuranceTypeValues');
                      }
                    "
                    >mdi-plus</v-icon
                  >
                </template>
                <template v-slot:default="dialog">
                  <v-card>
                    <v-card-title class="text-h5"></v-card-title>
                    <v-card-text>
                      <v-row style="margin-bottom: -30px">
                        <v-col
                          cols="3"
                          style="display: flex; justify-content: flex-start"
                        >
                          <div style="margin-top: 10px; display: block">
                            Название
                          </div>
                        </v-col>
                        <v-col
                          cols="8"
                          style="display: flex; justify-content: flex-start"
                        >
                          <v-text-field
                            label
                            solo
                            append
                            v-model="valuesDialogsMore.insuranceTypeValues.name"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row
                        v-for="(i, index) in valuesDialogsMore
                          .insuranceTypeValues.value"
                        :key="index"
                        style="margin-bottom: -40px"
                      >
                        <v-col cols="2" offset="1" style="padding-left: 0">
                          <span
                            style="
                              margin-top: 10px;
                              display: block;
                              font-size: 1.5em;
                              color: rgb(25 118 210);
                            "
                            >{{ index + 1 }}</span
                          >
                        </v-col>
                        <v-col
                          cols="5"
                          style="display: flex; justify-content: flex-start"
                        >
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                label
                                solo
                                append
                                type="number"
                                append-icon="mdi-percent-outline"
                                v-model="
                                  valuesDialogsMore.insuranceTypeValues.value[
                                    index
                                  ]
                                "
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <span>{{
                              sumDialogField("insuranceTypeValues", index)
                            }}</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="green darken-1"
                        text
                        @click="
                          () => {
                            dialog.value = false;
                            clearFieldsDialog('insuranceTypeValues');
                          }
                        "
                        >Отмена</v-btn
                      >
                      <v-btn
                        color="green darken-1"
                        text
                        @click="
                          () => {
                            dialog.value = !saveFieldsDialog(
                              'insuranceTypeValues'
                            );
                          }
                        "
                        >Добавить</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </template>
              </v-dialog>
            </div>
          </v-col>
        </v-row>
        <span
          style="
            display: block;
            margin-top: 15px;
            font-size: 1.2em;
            color: rgb(25 118 210);
            font-weight: 500;
          "
          >Амортизация</span
        >
        <v-divider style="border-color: rgb(25 118 210)"></v-divider>
        <v-row style="margin-top: 10px">
          <v-col cols="12" sm="12" md="12" style="display: flex">
            <span style="padding-top: 10px; padding-right: 10px"
              >Выплаты по амортизации</span
            >
            <div style="display: flex">
              <v-checkbox
                v-for="item in depreciationValues"
                :label="item.name"
                color="info"
                class="mr-5"
                style="margin-top: 6px"
                :value="item.name"
                :key="item.name"
                :readonly="checkDisabledFields(item)"
                hide-details
                v-model="calculateValue.depreciationValues"
              >
                <template v-slot:label>
                  <div>
                    <div v-if="item.more">
                      <v-hover v-slot="{ hover }">
                        <div>
                          <span
                            :style="
                              checkDisabledFields(item) ? 'color: #c2c2c2' : ''
                            "
                            >{{ item.name }}</span
                          >
                          <!-- <v-icon
                            v-if="hover"
                            color="red"
                            small
                            style="margin-bottom: 1px;"
                            @click="deleteMore('depreciationValues', item)"
                          >mdi-close</v-icon>-->
                          <v-icon
                            v-if="hover"
                            color="primary"
                            small
                            style="margin-bottom: 1px"
                            @click="changeMore('depreciationValues', item)"
                            >mdi-information-outline</v-icon
                          >
                          <div
                            v-else
                            style="
                              width: 16px;
                              height: 10px;
                              display: inline-block;
                            "
                          ></div>
                        </div>
                      </v-hover>
                    </div>
                    <div v-else>{{ item.name }}</div>
                  </div>
                </template>
              </v-checkbox>
              <v-dialog
                transition="dialog-bottom-transition"
                v-model="dialogMore.view['depreciationValues']"
                max-width="400"
              >
                <template v-slot:activator="{ on, attrs }">
                  <!-- <v-btn style="margin-left: -9px; margin-top: 3px;" fab dark small color="indigo" v-bind="attrs" v-on="on">
              <v-icon dark>mdi-plus</v-icon>
                  </v-btn>-->
                  <v-icon
                    style="height: 31px; width: 31px; margin-top: 7px"
                    v-bind="attrs"
                    v-on="on"
                    :disabled="calculateValue.leaseTermValues.length != 1"
                    @click="
                      () => {
                        setFormatDataDialog('depreciationValues');
                      }
                    "
                    >mdi-plus</v-icon
                  >
                </template>
                <template v-slot:default="dialog">
                  <v-card>
                    <v-card-title class="text-h5"></v-card-title>
                    <v-card-text>
                      <v-row style="margin-bottom: -30px">
                        <v-col
                          cols="3"
                          style="display: flex; justify-content: flex-start"
                        >
                          <div style="margin-top: 10px; display: block">
                            Название
                          </div>
                        </v-col>
                        <v-col
                          cols="8"
                          style="display: flex; justify-content: flex-start"
                        >
                          <v-text-field
                            label
                            solo
                            append
                            v-model="valuesDialogsMore.depreciationValues.name"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row
                        v-for="(i, index) in valuesDialogsMore
                          .depreciationValues.value"
                        :key="index"
                        style="margin-bottom: -40px"
                      >
                        <v-col cols="2" offset="1" style="padding-left: 0">
                          <span
                            style="
                              margin-top: 10px;
                              display: block;
                              font-size: 1.5em;
                              color: rgb(25 118 210);
                            "
                            >{{ index + 1 }}</span
                          >
                        </v-col>
                        <v-col
                          cols="5"
                          style="display: flex; justify-content: flex-start"
                        >
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                label
                                solo
                                append
                                type="number"
                                append-icon="mdi-percent-outline"
                                v-model="
                                  valuesDialogsMore.depreciationValues.value[
                                    index
                                  ]
                                "
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <span>{{
                              sumDialogField("depreciationValues", index)
                            }}</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                    </v-card-text>

                    <v-card-actions
                      v-if="dialogMore.change['depreciationValues']"
                    >
                      <v-btn
                        color="green darken-1"
                        text
                        @click="
                          () => {
                            dialog.value = false;
                            clearFieldsDialog('depreciationValues');
                          }
                        "
                        >Отмена</v-btn
                      >
                      <v-spacer />
                      <v-btn
                        color="red darken-1"
                        text
                        @click="
                          () => {
                            dialog.value =
                              !deleteFieldsDialog('depreciationValues');
                          }
                        "
                        >Удалить</v-btn
                      >
                      <v-btn
                        color="green darken-1"
                        text
                        @click="
                          () => {
                            dialog.value =
                              !changeFieldsDialog('depreciationValues');
                          }
                        "
                        >Сохранить</v-btn
                      >
                    </v-card-actions>
                    <v-card-actions v-else>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="green darken-1"
                        text
                        @click="
                          () => {
                            dialog.value = false;
                            clearFieldsDialog('depreciationValues');
                          }
                        "
                        >Отмена</v-btn
                      >
                      <v-btn
                        color="green darken-1"
                        text
                        @click="
                          () => {
                            dialog.value =
                              !saveFieldsDialog('depreciationValues');
                          }
                        "
                        >Добавить</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </template>
              </v-dialog>
            </div>
          </v-col>
        </v-row>
        <v-row style="margin-top: 10px">
          <v-col cols="12" sm="3" md="3">
            <span
              style="
                padding-top: 10px;
                padding-right: 10px;
                color: rgb(25 118 210);
                font-weight: 500;
              "
              >Дата графика</span
            >
            <v-dialog
              ref="dialogDateSchedule"
              v-model="dialog.date.schedule"
              :return-value.sync="calculateValue.date.schedule"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="calculateValue.date.schedule"
                  label
                  append-icon="mdi-calendar"
                  readonly
                  solo
                  dense
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="calculateValue.date.schedule" scrollable>
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="dialog.date.schedule = false"
                  >Отмена</v-btn
                >
                <v-btn
                  text
                  color="primary"
                  @click="
                    () => {
                      $refs.dialogDateSchedule.save(
                        calculateValue.date.schedule
                      );
                      changeDateSchedule(calculateValue.date.schedule);
                    }
                  "
                  >OK</v-btn
                >
              </v-date-picker>
            </v-dialog>
          </v-col>
          <v-col cols="12" offset="1" sm="3" md="3">
            <span
              style="
                padding-top: 10px;
                padding-right: 10px;
                color: rgb(25 118 210);
                font-weight: 500;
              "
              >Дата задатка</span
            >
            <v-dialog
              ref="dialogDateDeposit"
              v-model="dialog.date.deposit"
              :return-value.sync="calculateValue.date.deposit"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="calculateValue.date.deposit"
                  label
                  append-icon="mdi-calendar"
                  readonly
                  solo
                  dense
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="calculateValue.date.deposit" scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="dialog.date.deposit = false"
                  >Отмена</v-btn
                >
                <v-btn
                  text
                  color="primary"
                  @click="
                    $refs.dialogDateDeposit.save(calculateValue.date.deposit)
                  "
                  >OK</v-btn
                >
              </v-date-picker>
            </v-dialog>
          </v-col>
          <v-col cols="12" offset="1" sm="3" md="3">
            <span
              style="
                padding-top: 10px;
                padding-right: 10px;
                color: rgb(25 118 210);
                font-weight: 500;
              "
              >Дата 1 платежа</span
            >
            <v-dialog
              ref="dialogDateFirstPay"
              v-model="dialog.date.firstPay"
              :return-value.sync="calculateValue.date.firstPay"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="calculateValue.date.firstPay"
                  label
                  append-icon="mdi-calendar"
                  readonly
                  solo
                  dense
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="calculateValue.date.firstPay" scrollable>
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="dialog.date.firstPay = false"
                  >Отмена</v-btn
                >
                <v-btn
                  text
                  color="primary"
                  @click="
                    $refs.dialogDateFirstPay.save(calculateValue.date.firstPay)
                  "
                  >OK</v-btn
                >
              </v-date-picker>
            </v-dialog>
          </v-col>
        </v-row>

        <div style="display: flex">
          <span
            style="
              display: block;
              margin-top: 15px;
              font-size: 1.2em;
              color: rgb(25 118 210);
              font-weight: 500;
            "
            >Дополнительные расходы</span
          >
          <v-icon
            style="
              width: 20px;
              height: 20px;
              margin-top: 20px;
              margin-left: 5px;
            "
            @click="() => createMore()"
            >mdi-plus</v-icon
          >
        </div>
        <v-divider style="border-color: rgb(25 118 210)"></v-divider>
        <div>
          <div
            v-for="(itemMore, index) in this.calculateValue.more"
            :key="index"
          >
            <v-row style="margin-top: 10px">
              <v-col cols="3" style="display: flex; padding-bottom: 0">
                <span style="padding-top: 10px; padding-right: 10px"
                  >Прочие затраты с НДС</span
                >
              </v-col>
              <v-col cols="9" style="display: flex; padding-bottom: 0">
                <v-text-field
                  v-model="calculateValue.more[index].name"
                  type="number"
                  solo
                  dense
                  placeholder="Введите сумму"
                  style="margin-right: 20px"
                />
                <v-text-field
                  v-model="calculateValue.more[index].comments"
                  solo
                  dense
                  placeholder="Комментарий"
                  style="margin-right: 20px"
                />
                <v-text-field
                  v-model="calculateValue.more[index].price"
                  type="number"
                  solo
                  dense
                  style="margin-right: 20px; width: 80px"
                  placeholder="Платеж"
                />
                <v-checkbox
                  label="повторять"
                  color="info"
                  v-model="calculateValue.more[index].toRepeat"
                  class="mr-5"
                  style="margin-top: 6px"
                />
                <v-btn
                  style="margin-top: 4px"
                  @click="deleteMorePay(index)"
                  color="red"
                  text
                  icon
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row style="margin-top: 0">
              <v-col cols="9" offset="3" style="display: flex; padding-top: 0">
                <div style="display: flex">
                  <v-checkbox
                    v-for="item in currencyValuesMoreE(index)"
                    :label="item.name"
                    color="info"
                    :value="item.name"
                    :key="item.name"
                    hide-details
                    class="mr-5"
                    style="margin-top: 6px"
                    v-model="calculateValue.more[index].currency"
                  >
                    <template v-slot:label>
                      <div>
                        <div v-if="item.more">
                          <v-hover v-slot="{ hover }">
                            <div>
                              <span>{{ item.name }}</span>
                              <v-icon
                                v-if="hover"
                                color="red"
                                small
                                style="margin-bottom: 3px"
                                @click="deleteMore('more', item, index)"
                                >mdi-close</v-icon
                              >
                              <div
                                v-else
                                style="
                                  width: 16px;
                                  height: 10px;
                                  display: inline-block;
                                "
                              ></div>
                            </div>
                          </v-hover>
                        </div>
                        <div v-else>{{ item.name }}</div>
                      </div>
                    </template>
                  </v-checkbox>
                </div>
                <v-dialog
                  v-show="!onSpecialCase"
                  transition="dialog-bottom-transition"
                  max-width="600"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      style="height: 31px; width: 31px; margin-top: 7px"
                      v-bind="attrs"
                      v-on="on"
                      @click="() => updateDataDialog('more', index)"
                      >mdi-plus</v-icon
                    >
                  </template>
                  <template v-slot:default="dialog">
                    <v-card>
                      <v-card-title class="text-h5"></v-card-title>
                      <v-card-text>
                        <v-row>
                          <v-col
                            cols="6"
                            style="display: flex; justify-content: flex-start"
                          >
                            <div style="padding-top: 10px; padding-right: 10px">
                              Валюта
                            </div>
                            <v-select
                              :value="
                                valuesDialogsMore.more[index].currency.name
                              "
                              :items="currencyValuesMoreEMore(index)"
                              item-text="name"
                              item-value="name"
                              solo
                              @change="
                                (val) => changeCurrencyMore('more', val, index)
                              "
                            ></v-select>
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="green darken-1"
                          text
                          @click="
                            () => {
                              dialog.value = false;
                              clearFieldsDialog('more', index);
                            }
                          "
                          >Отмена</v-btn
                        >
                        <v-btn
                          color="green darken-1"
                          text
                          @click="
                            () => {
                              dialog.value = !saveFieldsDialog('more', index);
                            }
                          "
                          >Добавить</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </template>
                </v-dialog>
              </v-col>
            </v-row>
          </div>
        </div>
      </div>
      <v-row style="margin-top: 10px">
        <v-col cols="12" sm="3" md="3">
          <span
            style="
              padding-top: 10px;
              padding-right: 10px;
              color: rgb(25 118 210);
              font-weight: 500;
            "
            >Руководитель проекта</span
          >
          <v-text-field
            @click="openUserDialog"
            @change="openUserDialog"
            :value="calculateValue.projectManager.name"
            label
            placeholder
            solo
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" offset="1" sm="3" md="3">
          <v-btn
            :disabled="!checkFiles"
            style="margin-top: 24px"
            color="primary"
            @click="openFilesPage()"
            >История</v-btn
          >
        </v-col>
        <v-col cols="12" offset="1" sm="3" md="3">
          <v-btn style="margin-top: 24px" color="primary" @click="getCalculate"
            >Сформировать</v-btn
          >
        </v-col>
      </v-row>
    </div>
    <v-snackbar v-model="error.onError" :multi-line="true">
      {{ error.message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="closeError()"
          >Закрыть</v-btn
        >
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
export default {
  data() {
    return {
      filterTemplate: "0",
      moreFields: {
        currencyValues: [],
        involvementValues: [],
        contractCurrencyValues: [],
        leaseTermValues: [],
        repaymentTypeValues: [],
        insuranceTypeValues: [],
        depreciationValues: [],
        more: [],
      },
      default: {
        moreFields: {
          currencyValues: [],
          involvementValues: [],
          contractCurrencyValues: [],
          leaseTermValues: [],
          repaymentTypeValues: [],
          insuranceTypeValues: [],
          depreciationValues: [],
          more: [],
        },
        calculateValue: {
          deal: { title: null },
          price: "0",
          priceNDS: false,
          leaseTermValues: [],
          repaymentTypeValues: [],
          redemption: "1",
          contractCurrencyValues: [],
          currencyValues: [],
          involvementValues: [],
          insuranceRate: "0",
          insuranceTypeValues: [],
          depreciationValues: [],
          paymentRate: "0",
          interestRate: {},
          date: {
            schedule: "",
            deposit: "",
            firstPay: "",
          },
          projectManager: {
            name: "",
          },
          more: [],
          template: {},
        },
      },
      valuesDialogsMore: {
        currencyValues: {
          name: "",
          value: "",
        },
        involvementValues: {
          name: "",
          value: "",
          type: "percent",
        },
        contractCurrencyValues: {
          name: "",
          value: "",
        },
        leaseTermValues: {
          name: "",
          value: "",
        },
        insuranceTypeValues: {
          name: "",
          value: [],
        },
        repaymentTypeValues: {
          name: "",
          value: "",
        },
        depreciationValues: {
          name: "",
          value: [],
        },
        more: [],
      },
      dialog: {
        currencyValues: false,
        date: {
          schedule: false,
          deposit: false,
          firstPay: false,
        },
      },
      calculateValue: {
        deal: { title: null },
        price: "0",
        priceNDS: false,
        leaseTermValues: [],
        repaymentTypeValues: [],
        redemption: "1",
        contractCurrencyValues: [],
        currencyValues: [],
        involvementValues: [],
        insuranceRate: "0",
        insuranceTypeValues: [],
        depreciationValues: [],
        paymentRate: "0",
        interestRate: {},
        date: {
          schedule: "",
          deposit: "",
          firstPay: "",
        },
        projectManager: {
          name: "",
        },
        more: [],
        template: {},
      },
      moreDefault: {
        name: "",
        comments: "",
        price: "",
        currency: [],
        toRepeat: false,
      },
      onSpecialCase: false,
      error: {
        onError: false,
        message: "",
      },
      dialogReward: {},
      checkFiles: false,
      depreciationValuesDialog: false,
      dialogView: {
        depreciationValues: false,
      },
      dialogChange: {
        depreciationValues: false,
      },
      dialogMore: {
        view: {
          depreciationValues: false,
        },
        change: {
          depreciationValues: false,
        },
        data: {
          depreciationValues: {},
        },
      },
    };
  },
  computed: {
    ...mapGetters([
      "TEMPLATES",
      "TEMPLATE",
      "CURRENCY__BYN",
      "CURRENCY__default",
      "CURRENCY__more",
      "ATTRACTIVENESS_RATE__ITEM",
      "DEFAULT_FIELDS",
      "SPECIAL_CASES",
      "SPECIAL_CASE",
      "CALCULATE__MORE",
      "CALCULATE__VALUE",
      "SETTINGS__REPEYMENT_TYPE",
      "SETTINGS__EXCEL",
    ]),
    templateId() {
      return this.calculateValue.template
        ? this.calculateValue.template.id
        : null;
    },
    currencyValues() {
      return [
        ...this.CURRENCY__BYN,
        ...this.CURRENCY__default,
        ...this.moreFields.currencyValues.map((el) => ({
          ...el,
          more: true,
        })),
      ];
    },
    currencyValuesMore() {
      return this.CURRENCY__more.filter((el) => {
        return !this.currencyValues.find((elUs) => elUs.name == el.name);
      });
    },
    involvementValues() {
      return [
        ...this.DEFAULT_FIELDS.involvementValues,
        ...this.moreFields.involvementValues.map((el) => ({
          ...el,
          more: true,
        })),
      ];
    },
    contractCurrencyValues() {
      return [
        ...this.DEFAULT_FIELDS.contractCurrencyValues,
        ...this.moreFields.contractCurrencyValues.map((el) => ({
          ...el,
          more: true,
        })),
      ];
    },
    contractCurrencyValuesMore() {
      return this.CURRENCY__more.filter((el) => {
        return !this.contractCurrencyValues.find(
          (elUs) => elUs.name == el.name
        );
      });
    },
    leaseTermValues() {
      return [
        ...this.DEFAULT_FIELDS.leaseTermValues,
        ...this.moreFields.leaseTermValues.map((el) => ({
          ...el,
          more: true,
        })),
      ];
    },
    repaymentTypeValues() {
      return [
        ...this.DEFAULT_FIELDS.repaymentTypeValues,
        ...this.moreFields.repaymentTypeValues.map((el) => ({
          ...el,
          more: true,
        })),
      ];
    },
    insuranceTypeValues() {
      return [
        ...this.DEFAULT_FIELDS.insuranceTypeValues.slice(0, -1),
        ...this.moreFields.insuranceTypeValues.map((el) => ({
          ...el,
          more: true,
        })),
        ...this.DEFAULT_FIELDS.insuranceTypeValues.slice(-1),
      ];
    },
    depreciationValues() {
      return [
        ...this.DEFAULT_FIELDS.depreciationValues,
        ...this.moreFields.depreciationValues.map((el) => ({
          ...el,
          more: true,
        })),
      ];
    },
    templateSelect() {
      let result = [];
      let template = [
        ...this.TEMPLATES,
        ...this.SPECIAL_CASES.map((el) => ({
          ...el,
          typeClient: 0,
          specialCase: true,
        })),
      ];
      result = template.filter((el) => el.typeClient == this.filterTemplate);
      return result;
    },
    currencyCalc: {
      set(val) {
        if (val.length > 1) {
          val.shift();
        }
        this.calculateValue.currencyValues = val;
      },
      get() {
        return this.calculateValue.currencyValues;
      },
    },
    listReward() {
      let arr = [];
      for (let valueCurrency of this.calculateValue.contractCurrencyValues) {
        let id = valueCurrency;
        let name = valueCurrency;
        console.log(id, this.dialogReward);
        let changeItem = this.dialogReward[id];
        let value = changeItem ? changeItem.value : 0;
        arr.push({
          id,
          valueCurrency,
          value,
          name,
        });
      }
      return arr;
    },
  },
  methods: {
    // changeValues(typeDialog, typeValue, value) {
    //   this.valuesDialogsMore['typeDialog']['typeValue'] = value
    // }
    ...mapActions([
      "FILES__ADD",
      "FILES__OPEN",
      "FILES__CHECK",
      "FILES__LOAD",
      "FIT_WINDOW",
      "CALCULATE__MORE_SAVE",
      "CALCULATE__MORE_GET",
      "CALCULATE__VALUE_SAVE",
      "CALCULATE__VALUE_GET",
      "CALCULATE__CREATE",
    ]),
    moment,
    commaToDot(value) {
      let regexp = /,/gi;
      return value.replace(regexp, ".");
    },
    getTemplate(value) {
      let result = null;
      if (this.TEMPLATE(value).id) {
        result = this.TEMPLATE(value);
      } else if (this.SPECIAL_CASE(value).id) {
        result = {
          ...this.SPECIAL_CASE(value),
          typeClient: 0,
          specialCase: true,
        };
      }
      return result;
    },
    getMounthName(value) {
      let result = "";
      if (value % 10 == 1) {
        result = "месяц";
      } else if (
        [5, 6, 7, 8, 9, 0].includes(value % 10) ||
        [11, 12, 13, 14].includes(value % 100)
      ) {
        result = "месяцев";
      } else {
        result = "месяца";
      }
      return result;
    },
    sumDialogField(type) {
      return this.valuesDialogsMore[type].value.reduce(
        (partial_sum, a) => (Number(partial_sum) + Number(a)).toFixed(2),
        0
      );
    },
    involvementValuesItem(name) {
      let item = [
        ...this.DEFAULT_FIELDS.involvementValues,
        ...this.moreFields.involvementValues.map((el) => ({
          ...el,
          more: true,
        })),
      ].find((el) => el.name == name);
      return item;
    },
    currencyValuesItem(name) {
      let item = [
        ...this.CURRENCY__BYN,
        ...this.CURRENCY__default,
        ...this.CURRENCY__more,
      ].find((el) => el.name == name);
      item.rate = this.ATTRACTIVENESS_RATE__ITEM(item?.rateId);
      return item;
    },
    leaseTermValuesItem(name) {
      let item = [
        ...this.DEFAULT_FIELDS.leaseTermValues,
        ...this.moreFields.leaseTermValues.map((el) => ({
          ...el,
          more: true,
        })),
      ].find((el) => el.name == name);
      return item;
    },
    clearFieldsDialog(typeDialog, id) {
      if (typeDialog == "more") {
        for (let key in this.valuesDialogsMore[typeDialog][id].currency) {
          this.valuesDialogsMore[typeDialog][id][key] = "";
        }
      } else {
        for (let key in this.valuesDialogsMore[typeDialog]) {
          this.valuesDialogsMore[typeDialog][key] = "";
        }
      }
    },
    updateDataDialog(typeDialog, id) {
      if (typeDialog == "more") {
        this.valuesDialogsMore["more"][id].currency = {
          ...this.currencyValuesMoreEMore(id)[0],
        };
      } else {
        this.valuesDialogsMore[typeDialog] = {
          ...this[typeDialog + "More"][0],
        };
      }
    },
    saveFieldsDialog(typeDialog, id, change) {
      console.log("Enter", typeDialog, id, change);
      let mounthDialog = [
        "depreciationValues",
        "insuranceTypeValues",
        "repaymentTypeValues",
      ];
      let errorMessage = {
        monthSum100: "Сумма не равно 100",
        nameEmpty: "Название не введено",
        nameExists: "Название уже существует",
        monthEmpty: "Количество месяцев не введено",
        monthExists: "Уже существует",
      };

      let checkError = () => {
        let error = {
          onError: false,
          message: "",
        };
        if (mounthDialog.includes(typeDialog)) {
          if (this.sumDialogField(typeDialog) != 100) {
            error.onError = true;
            error.message = errorMessage.monthSum100;
          }
        }
        if (this.valuesDialogsMore[typeDialog].name == "") {
          error.onError = true;
          error.message = errorMessage.nameEmpty;
        }
        if (
          typeDialog == "leaseTermValues" &&
          this.valuesDialogsMore[typeDialog].value == ""
        ) {
          error.onError = true;
          error.message = errorMessage.monthEmpty;
        }
        if (
          [
            ...this.DEFAULT_FIELDS[typeDialog],
            ...this.moreFields[typeDialog],
          ].find((el) => el.name == this.valuesDialogsMore[typeDialog].name)
        ) {
          console.log(
            "====",
            [
              ...this.DEFAULT_FIELDS[typeDialog],
              ...this.moreFields[typeDialog],
            ].findIndex(
              (el) => el.name == this.valuesDialogsMore[typeDialog].name
            )
          );
          if (
            !(
              this.moreFields[typeDialog].findIndex(
                (el) => el.name == this.valuesDialogsMore[typeDialog].name
              ) == change && change !== undefined
            )
          ) {
            if (typeDialog == "leaseTermValues") {
              error.onError = true;
              error.message = errorMessage.monthExists;
            } else {
              error.onError = true;
              error.message = errorMessage.nameExists;
            }
          }
        }
        return error;
      };

      if (typeDialog == "involvementValues") {
        if (this.valuesDialogsMore[typeDialog].type == "money") {
          this.valuesDialogsMore[typeDialog].name =
            this.valuesDialogsMore[typeDialog].value;
        } else {
          this.valuesDialogsMore[typeDialog].name =
            this.valuesDialogsMore[typeDialog].value + "%";
        }
      }
      if (typeDialog == "leaseTermValues") {
        this.valuesDialogsMore[typeDialog].name =
          this.valuesDialogsMore[typeDialog].value +
          " " +
          this.getMounthName(this.valuesDialogsMore[typeDialog].value);
      }
      if (typeDialog == "more") {
        if (
          ![
            ...this.DEFAULT_FIELDS.currencyValues,
            ...this.moreFields.more[id].currency,
          ].find(
            (el) => el.name == this.valuesDialogsMore.more[id].currency.name
          ) &&
          this.valuesDialogsMore.more[id].currency.name != ""
        ) {
          this.moreFields.more[id].currency.push({
            ...this.valuesDialogsMore.more[id].currency,
          });
          this.clearFieldsDialog("more", id);
          return true;
        } else {
          return false;
        }
      }
      let error = checkError();
      if (!error.onError) {
        if (change !== undefined) {
          console.log("--- PFT<BCM", change);
          this.moreFields[typeDialog][change] = {
            ...this.valuesDialogsMore[typeDialog],
          };
          this.moreFields[typeDialog] = [...this.moreFields[typeDialog]];
          this.dialogMore.view[typeDialog] = false;
          this.dialogMore.change[typeDialog] = false;
        } else {
          this.moreFields[typeDialog].push({
            ...this.valuesDialogsMore[typeDialog],
          });
        }
        this.clearFieldsDialog(typeDialog);
        return true;
      } else {
        this.error = error;
        return false;
      }
    },
    deleteFieldsDialog(typeDialog) {
      let deleteElement = this.moreFields[typeDialog].findIndex(
        (el) => el.name == this.dialogMore.data[typeDialog].name
      );
      if (deleteElement >= 0) {
        this.moreFields[typeDialog].splice(deleteElement, 1);
      }
      this.dialogMore.view[typeDialog] = false;
      this.dialogMore.change[typeDialog] = false;
    },
    changeFieldsDialog(typeDialog) {
      let changeElement = this.moreFields[typeDialog].findIndex(
        (el) => el.name == this.dialogMore.data[typeDialog].name
      );
      this.saveFieldsDialog(typeDialog, undefined, changeElement);
    },
    changeTemplate(val) {
      console.log(val, " = CHANGE TEMPLATE");
      this.calculateValue.template = this.getTemplate(val);
      if (this.calculateValue.template != null) {
        if (this.calculateValue.template.specialCase) {
          console.log("CT", true);
          this.onSpecialCase = true;
        } else {
          console.log("CT", false);
          this.onSpecialCase = false;
        }
      }
    },
    changeTypeInvolvement() {
      this.valuesDialogsMore.involvementValues.type =
        this.valuesDialogsMore.involvementValues.type != "money"
          ? "money"
          : "percent";
    },
    changeCurrencyMore(typeDialog, val, id) {
      if (typeDialog == "more") {
        this.valuesDialogsMore.more[id].currency = {
          ...this.CURRENCY__more.find((el) => el.name == val),
        };
      } else {
        this.valuesDialogsMore[typeDialog] = {
          ...this.CURRENCY__more.find((el) => el.name == val),
        };
      }
    },
    checkDisabledLeaseTermValues(item) {
      let result = false;
      let blockList = [
        "repaymentTypeValues",
        "insuranceTypeValues",
        "depreciationValues",
      ];
      let valueMounth = null;
      blockList.forEach((field) => {
        let checkValueActiveMore = this.calculateValue[field].find((elCalc) => {
          return this.moreFields[field].find((elMore) => elMore.name == elCalc);
        });
        if (checkValueActiveMore != undefined) {
          valueMounth = this.moreFields[field].find(
            (elMore) => elMore.name == checkValueActiveMore
          ).value.length;
        }
        if (checkValueActiveMore) {
          console.log("checkValueActiveMore = ", checkValueActiveMore);
        }
      });
      if (valueMounth != null) {
        result = valueMounth != item.value;
      }
      return result;
    },
    checkDisabledFields(item) {
      let blockList = [
        "repaymentTypeValues",
        "insuranceTypeValues",
        "depreciationValues",
      ];
      let result = false;
      if (item.more) {
        if (this.calculateValue.leaseTermValues.length > 1) {
          result = true;
        } else {
          if (this.calculateValue.leaseTermValues.length == 1) {
            let mounth = this.leaseTermValues.find(
              (el) => this.calculateValue.leaseTermValues[0] == el.name
            ).value;
            if (item.value.length != mounth) {
              result = true;
            }
          } else {
            let valueMounth = null;
            blockList.forEach((field) => {
              let checkValueActiveMore = this.calculateValue[field].find(
                (elCalc) => {
                  return this.moreFields[field].find(
                    (elMore) => elMore.name == elCalc
                  );
                }
              );
              if (checkValueActiveMore != undefined) {
                valueMounth = this.moreFields[field].find(
                  (elMore) => elMore.name == checkValueActiveMore
                ).value.length;
              }
              if (checkValueActiveMore) {
                console.log("checkValueActiveMore = ", checkValueActiveMore);
              }
            });
            if (valueMounth != null) {
              result = valueMounth != item.value.length;
            }
          }
        }
      }
      return result;
    },

    deleteMore(typeDialog, val, id) {
      let moreFieldsType = {};
      let calculateFieldsType = {};
      if (typeDialog != "more") {
        moreFieldsType = this.moreFields[typeDialog];
        calculateFieldsType = this.calculateValue[typeDialog];
      } else {
        moreFieldsType = this.moreFields[typeDialog][id].currency;
        calculateFieldsType = this.calculateValue[typeDialog][id].currency;
      }

      let deleteId = moreFieldsType.findIndex((el) => el.name == val.name);
      let index = calculateFieldsType.findIndex((el) => el == val.name);
      if (index != -1) {
        calculateFieldsType.splice(index, 1);
      }
      moreFieldsType.splice(deleteId, 1);
    },
    setDataStart() {
      this.calculateValue.date.schedule = moment().format("YYYY-MM-DD");
      this.calculateValue.date.deposit = moment()
        .add(5, "d")
        .format("YYYY-MM-DD");
      this.calculateValue.date.firstPay = moment()
        .add(35, "d")
        .format("YYYY-MM-DD");
    },
    setFormatDataDialog(type) {
      if (this.dialogMore.change[type]) {
        this.dialogMore.change[type] = false;
      }

      let itemLeaseTerm = this.leaseTermValuesItem(
        this.calculateValue.leaseTermValues[0]
      );
      let countMonth = itemLeaseTerm.value;
      if (this.calculateValue.leaseTermValues.length == 1) {
        this.valuesDialogsMore[type].value = [...Array(countMonth)].map(
          () => ""
        );
      } else {
        console.log("ERROR - ошибка по месяцам");
      }
    },
    createMore() {
      this.calculateValue.more.push({ ...this.moreDefault });
      this.moreFields.more.push({ currency: [] });
      this.valuesDialogsMore.more.push({ currency: [] });
    },
    deleteMorePay(index) {
      this.calculateValue.more.splice(index, 1);
    },
    currencyValuesMoreE(index) {
      return [
        ...this.CURRENCY__BYN,
        ...this.CURRENCY__default,
        ...this.moreFields.more[index].currency.map((el) => ({
          ...el,
          more: true,
        })),
      ];
    },
    currencyValuesMoreEMore(index) {
      return this.CURRENCY__more.filter((el) => {
        return !this.currencyValuesMoreE(index).find(
          (elUs) => elUs.name == el.name
        );
      });
    },
    changeDateSchedule(value) {
      this.calculateValue.date.deposit = moment(value)
        .add(5, "d")
        .format("YYYY-MM-DD");
      this.calculateValue.date.firstPay = moment(value)
        .add(35, "d")
        .format("YYYY-MM-DD");
      ////
      this.setCurrencyNBRB(this.CURRENCY__more);
      this.setCurrencyNBRB(this.CURRENCY__default);
      this.setCurrencyNBRB(this.CURRENCY__more);
    },
    async openFilesPage() {
      await this.FILES__LOAD(this.calculateValue.deal);
    },
    openCRMDialog() {
      let typeCRM = ["deal"];
      let vm = this;
      // eslint-disable-next-line
      BX24.selectCRM(
        {
          entityType: typeCRM,
          multiple: false,
          value: {},
        },
        async function (item) {
          vm.calculateValue.deal = item.deal[0];
          vm.loadDataCalculate(item.deal[0]);
          console.log(item);
          vm.checkFiles = await vm.FILES__CHECK(item.deal[0]);
        }
      );
    },
    async loadDataCalculate(deal) {
      let id = deal.id;

      await this.CALCULATE__MORE_GET(id);
      this.moreFields = this.default.moreFields;
      Object.keys(this.CALCULATE__MORE).forEach((key) => {
        this.moreFields[key] = this.CALCULATE__MORE[key];
      });
      await this.CALCULATE__VALUE_GET(id);
      this.calculateValue = { ...this.default.calculateValue };
      if (Object.keys(this.CALCULATE__VALUE).length > 0) {
        Object.keys(this.CALCULATE__VALUE).forEach((key) => {
          let incMas = ["deal", "template"];
          if (!incMas.includes(key)) {
            this.calculateValue[key] = this.CALCULATE__VALUE[key];

            if (key == "interestRate") {
              this.dialogReward = { ...this.CALCULATE__VALUE[key] };
            } else if (key == "priceNDS") {
              this.calculateValue[key] = Boolean(
                Number(this.CALCULATE__VALUE[key])
              );
            }
          } else {
            if (key == "template") {
              if (this.CALCULATE__VALUE[key].id) {
                this.changeTemplate(this.CALCULATE__VALUE[key]?.id);
              }
            }
          }
        });
      } else {
        this.setDataStart();
      }

      // this.setDataStart();

      this.calculateValue.deal = deal;
      console.log("-=-=CALC = ", this.calculateValue);

      ////
      this.setCurrencyNBRB(this.CURRENCY__default);
      this.setCurrencyNBRB(this.CURRENCY__more);
    },
    openUserDialog() {
      let vm = this;
      // eslint-disable-next-line
      BX24.selectUser(function (item) {
        console.log(item);
        vm.calculateValue.projectManager = item;
      });
    },
    closeError() {
      this.error.onError = false;
    },

    setCurrencyNBRB(curr) {
      const params = "?ParamMode=2&onDate=" + this.calculateValue.date.schedule;

      curr.forEach((item) => {
        let request = new XMLHttpRequest();
        let url = 'https://api.nbrb.by/exrates/rates/';
        if (item.name === 'ЮАНЬ') url += 'CNY' + params;
        else                      url += item.name + params;
        request.responseType = "json";
        request.open("GET", url, true);

        request.addEventListener("readystatechange", () => {
          if (request.readyState === 4 && request.status === 200) {
            let obj = request.response; console.log(obj);
            item.value = Number((obj.Cur_OfficialRate / obj.Cur_Scale).toFixed(6));
          }
        });

        request.send();
      });
    },

    changeReward(item, value) {
      let changeItem = this.dialogReward[item.id];
      if (!changeItem) {
        this.dialogReward[item.id] = {
          valueCurrency: item.valueCurrency,
          value,
        };
      } else {
        changeItem.value = value;
      }
    },
    returnValueDialogReward(item) {
      return this.dialogReward[item.id] ? this.dialogReward[item.id].value : 0;
    },
    saveReward() {
      this.dialogReward = { ...this.dialogReward };
      this.calculateValue.interestRate = { ...this.dialogReward };
      return true;
    },
    cancelReward() {
      this.dialogReward = { ...this.calculateValue.interestRate };
    },

    changeMore(type, item) {
      this.dialogMore.view["depreciationValues"] = true;
      this.dialogMore.change["depreciationValues"] = true;
      this.dialogMore.data["depreciationValues"] = item;
      this.valuesDialogsMore[type].value = item.value;
      this.valuesDialogsMore[type].name = item.name;

      // let itemLeaseTerm = this.leaseTermValuesItem(
      //   this.calculateValue.leaseTermValues[0]
      // );
      // let countMonth = itemLeaseTerm.value;
      // if (this.calculateValue.leaseTermValues.length == 1) {
      //   this.valuesDialogsMore[type].value = [...Array(countMonth)].map(
      //     () => ""
      //   );
      // } else {
      //   console.log("ERROR - ошибка по месяцам");
      // }
    },

    async getCalculate() {
      let listError = {
        template: "Не выбран договор",
        deal: "Не выбрана сделка",
        price: "Не заполнена стоимость",
        redemption: "Не заполнена выкупная стоимость",
        currencyValues: "Не выбрана валюта",
        contractCurrencyValues: "Не выбрана валюта",
        involvementValues: "Не выбрано участие клиента",
        interestRate: "Не заполнена процентная ставна",
        paymentRate: "Не заполнен увеличенный курс оплаты",
        leaseTermValues: "Не выбрано количество месяцев",
        repaymentTypeValues: "Не выбран тип гашение",
        insuranceRate: "Не введён тариф",
        insuranceTypeValues: "Не выбрат тип страховки",
        depreciationValues: "Не выбрана амортизация",
        more: "Не заполнены доп. затраты",
      };
      let examinationDataCaluculation = () => {
        let onError = false;
        let message = "";
        let examinationInterestRate = () => {
          return (
            this.calculateValue.contractCurrencyValues.filter((el) => {
              return this.calculateValue.interestRate[el]?.value != "";
            }).length > 0
          );
        };
        let examinationMore = () => {
          let result = true;
          if (this.calculateValue.more) {
            if (Array.isArray(this.calculateValue.more)) {
              this.calculateValue.more.forEach((el) => {
                if (
                  el.name == "" ||
                  el.price == "" ||
                  el.price == 0 ||
                  el.currency.length == 0
                )
                  result = false;
              });
            }
          }
          return result;
        };
        if (!this.calculateValue.deal.title) {
          message = listError.deal;
          onError = true;
        } else if (!this.calculateValue.template?.name) {
          message = listError.template;
          onError = true;
        } else if (this.calculateValue.price == 0) {
          message = listError.price;
          onError = true;
        } else if (this.calculateValue.redemption == 0) {
          message = listError.redemption;
          onError = true;
        } else if (this.calculateValue.currencyValues.length == 0) {
          message = listError.currencyValues;
          onError = true;
        } else if (this.calculateValue.involvementValues.length == 0) {
          message = listError.involvementValues;
          onError = true;
        } else if (this.calculateValue.contractCurrencyValues.length == 0) {
          message = listError.contractCurrencyValues;
          onError = true;
        } else if (!this.calculateValue.paymentRate) {
          message = listError.paymentRate;
          onError = true;
        } else if (this.calculateValue.leaseTermValues.length == 0) {
          message = listError.leaseTermValues;
          onError = true;
        } else if (this.calculateValue.repaymentTypeValues.length == 0) {
          message = listError.repaymentTypeValues;
          onError = true;
        } else if (!this.calculateValue.insuranceRate) {
          message = listError.insuranceRate;
          onError = true;
        } else if (this.calculateValue.insuranceTypeValues.length == 0) {
          message = listError.insuranceTypeValues;
          onError = true;
        } else if (this.calculateValue.depreciationValues.length == 0) {
          message = listError.depreciationValues;
          onError = true;
        } else if (!examinationInterestRate()) {
          message = listError.interestRate;
          onError = true;
        } else if (!examinationMore()) {
          message = listError.more;
          onError = true;
        }

        return { onError, message };
      };
      let examinationPrice = () => {
        let error = { onError: false, message: "" };
        if (!(this.calculateValue.price > 0)) {
          error.onError = false;
          error.errormessage = listError.message;
        } else if (this.calculateValue.currencyValues.length == 0) {
          error.message = listError.currencyValues;
          error.onError = true;
        }
        return error;
      };
      let checkTemplate = () => {
        return !!this.calculateValue.template.specialCase;
      };
      let commaToDot = (value) => {
        return String(value).replace(/,/gi, ".");
      };
      console.log("this.calculateValue = ", this.calculateValue);
      Object.values(this.calculateValue.interestRate).forEach((el) => {
        el.value = commaToDot(el.value);
      });
      this.calculateValue.involvementValues.forEach(
        (el) => (el = commaToDot(el))
      );
      this.calculateValue.leaseTermValues.forEach(
        (el) => (el = commaToDot(el))
      );
      let error = checkTemplate()
        ? examinationPrice()
        : examinationDataCaluculation();

      let collectionData = () => {
        let dataCalc = {
          deal: this.calculateValue.deal,
          tempalte: this.calculateValue.template,
          price: commaToDot(this.calculateValue.price),
          priceNDS: this.calculateValue.priceNDS,
          involvementValues: this.calculateValue.involvementValues.map((el) =>
            this.involvementValuesItem(el)
          ),
          currency: this.calculateValue.currencyValues.map((el) =>
            this.currencyValuesItem(el)
          ),
          contractCurrency: this.calculateValue.contractCurrencyValues.map(
            (el) => this.currencyValuesItem(el)
          ),
          redemption: commaToDot(this.calculateValue.redemption),
          interestRate: this.calculateValue.interestRate,
          paymentRate: commaToDot(this.calculateValue.paymentRate),
          leaseTerm: this.calculateValue.leaseTermValues.map((el) =>
            this.leaseTermValuesItem(el)
          ),
          repaymentType: [],
          insurance: {
            rate: this.calculateValue.insuranceRate,
            type: [],
          },
          depreciation: [],
          projectManager: this.calculateValue.projectManager,
          more: [
            this.calculateValue.more.map((el) => {
              return {
                name: el.name,
                comments: el.comments,
                price: el.price,
                toRepeat: el.toRepeat,
                currency: el.currency.map((el) => this.currencyValuesItem(el)),
              };
            }),
          ],
          date: {
            ...this.calculateValue.date,
          },
        };
        for (let item of this.calculateValue.repaymentTypeValues) {
          let elRepaymentType = this.repaymentTypeValues.find(
            (el) => el.name == item
          );
          if (elRepaymentType.more) {
            dataCalc.repaymentType.push({
              mounth: { [elRepaymentType.name]: elRepaymentType.value },
            });
          } else {
            dataCalc.repaymentType.push({
              defaults: {
                name: elRepaymentType.value,
                data: this.SETTINGS__REPEYMENT_TYPE.find(
                  (el) => el.name == elRepaymentType.name
                )?.data,
              },
            });
          }
        }
        for (let item of this.calculateValue.insuranceTypeValues) {
          let elInsurance = this.insuranceTypeValues.find(
            (el) => el.name == item
          );
          if (elInsurance.more) {
            dataCalc.insurance.type.push({
              mounth: { [elInsurance.name]: elInsurance.value },
            });
          } else {
            dataCalc.insurance.type.push({ defaults: elInsurance.value });
          }
        }
        for (let item of this.calculateValue.depreciationValues) {
          let elDepreciation = this.depreciationValues.find(
            (el) => el.name == item
          );
          if (elDepreciation.more) {
            dataCalc.depreciation.push({
              mounth: { [elDepreciation.name]: elDepreciation.value },
            });
          } else {
            dataCalc.depreciation.push({ defaults: elDepreciation.value });
          }
        }

        return dataCalc;
      };
      let collectionDataSpecialCase = () => {
        let item = {
          deal: this.calculateValue.deal,
          price: this.calculateValue.price,
          priceNDS: this.calculateValue.priceNDS,
          currencyValues: this.calculateValue.currencyValues.map((el) =>
            this.currencyValuesItem(el)
          ),
          template: { ...this.calculateValue.template },
        };
        item.template.currencyValues = item.template.currencyValues.map((el) =>
          this.currencyValuesItem(el)
        );
        item.template.leaseTermValues = item.template.leaseTermValues.map(
          (el) => this.leaseTermValuesItem(el)
        );
        item.template.involvementValues = item.template.involvementValues.map(
          (el) => this.involvementValuesItem(el)
        );
        return item;
      };
      console.log(error);
      console.log("CALC ++", this.calculateValue);
      if (error.onError) {
        console.log(collectionData(), " Error");
        this.error = error;
      } else {
        let calcValues = checkTemplate()
          ? collectionDataSpecialCase()
          : collectionData();
        this.saveFieldsDeal();
        await this.openFilesPage();
        console.log("calcValues - ", calcValues);
        calcValues.excel = this.SETTINGS__EXCEL;
        this.CALCULATE__CREATE({ calcValues, specialCase: checkTemplate() });
      }
    },
    saveFieldsDeal() {
      let dateMore = {
        data: this.moreFields,
        id: this.calculateValue.deal.id,
      };
      let dateValue = {
        data: this.calculateValue,
        id: this.calculateValue.deal.id,
      };
      console.log("+++++", dateValue);
      this.CALCULATE__MORE_SAVE(dateMore);
      this.CALCULATE__VALUE_SAVE(dateValue);
    },
  },
  mounted() {
    // eslint-disable-next-line
    const info = BX24.placement.info();
    if (info.placement == "CRM_DEAL_DETAIL_TAB") {
      let vm = this;
      // eslint-disable-next-line
      BX24.callMethod(
        "crm.deal.get",
        { id: info.options.ID },
        async (result) => {
          if (result.error()) {
            console.error(result.error());
          } else {
            const deal = {
              title: result.data().TITLE,
              id: "D_" + result.data().ID,
            };
            vm.calculateValue.deal = deal;
            vm.loadDataCalculate(deal);
            console.log(deal);
            vm.checkFiles = await vm.FILES__CHECK(deal);
          }
        }
      );
    }
    console.log("START");
    this.setDataStart();
  },
};
</script>

<style scoped>
</style>

