export default {
	state: {
		files: [],
		openPage: false,
		checkFiles: false,
		status: {
			loadHistory: false,
			formatic: false
		},
		load: {
			total: 0,
			status: 0 
		}
	},
	getters: {
		FILES(state) {
			return state.files;
		},
		FILES__PAGE_STATUS(state) {
			return state.openPage;
		},
		FILES__CHECK(state) {
			return state.checkFiles;
		},
		FILES__STATUS_LOAD(state) {
			return state.load;
		}

	},
	mutations: {
		FILES__CHANGE_STATUS_PAGE(state, value) {
			document.documentElement.scrollTop = 0
			state.openPage = value
		},
		FILES__ADD(state, value) {
			state.files = [...state.files, value]
		},
		FILES__CHECK_UPDATE(state, value) {
			state.files = [];
			state.status.loadHistory = false;
			state.status.formatic = false;
			state.checkFiles = value;
		},
		FILES__LOAD(state, value) {
			console.log(value);
			state.status.loadHistory = true;
			state.files = [...state.files, ...value];
		},
		FILE__LOAD_TOTAL_SET(state, value) {
			state.load.total = value
		},
		FILE__SET_LOAD_STATUS(state,value) {
			state.load.status = value;
		},
		FILE__UPDATE_LOAD_STATUS(state) {
			if (state.load.status < state.load.total) {
				state.load.status++;
			} else {
				state.load.status = state.load.total
			}
		}

	},
	actions: {
		FILES__ADD({commit}, item) {
			console.log('ITEM', item)
			commit('FILE__UPDATE_LOAD_STATUS')
			commit('FILES__ADD', item)
		},
		FILES__OPEN({commit}) {
			commit('FILES__CHANGE_STATUS_PAGE', true)
		},
		FILES__CLOSE({commit}) {
			commit('FILES__CHANGE_STATUS_PAGE', false)
		},
		FILES__CHECK({commit}, deal) {
			return new Promise((resolve) => {
				let params = { id: deal.id };
				fetch(this.state.url + 'files/check?' + new URLSearchParams(params), {
					method: 'get',
				}).then(async (res) => {
					let item = await res.json();
					commit('FILES__CHECK_UPDATE', item);
					resolve(item)
				})
			})
		},
		FILES__LOAD({commit, state}, deal) {
			return new Promise((resolve) => {
				let params = { id: deal.id };
				if (!state.status.loadHistory) {
					fetch(this.state.url + 'files/load?' + new URLSearchParams(params), {
						method: 'get',
					}).then(async (res) => {
						let item = await res.json();
						commit('FILES__LOAD', item);
						commit('FILES__CHANGE_STATUS_PAGE', true);
						resolve();
					}).catch(async () => {
						resolve();
					})
				} else {
					commit('FILES__CHANGE_STATUS_PAGE', true);
					resolve();
				}
			})
		}

	}
}