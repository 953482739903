<template>
  <v-card style="padding: 14px; margin: 10px" elevation="2">
    <div>
      <v-row style="margin: 4px 0px 7px">
        <v-btn @click="closePage" text color="primary">Назад</v-btn>
        <v-spacer></v-spacer>
        <div style="margin-top: 6px">
          <strong style="font-size: 18px; color: #1e71b9; padding-right: 16px">Всего: {{ FILES.length }}</strong>
        </div>
      </v-row>
      <div>
        <div style="margin-bottom: 20px; display: flex; flex-direction: column">
          <div style="margin-bottom: 10px"></div>
          <strong v-if="FILES__STATUS_LOAD.status != FILES__STATUS_LOAD.total" style="
              font-size: 18px;
              color: #1e71b9;
              text-align: right;
              padding-right: 16px;
            ">{{
              FILES__STATUS_LOAD.status + " из " + FILES__STATUS_LOAD.total
            }}</strong>
          <v-progress-linear stream :buffer-value="((FILES__STATUS_LOAD.status + Number(1)) /
                FILES__STATUS_LOAD.total) *
              100
              " :value="(FILES__STATUS_LOAD.status / FILES__STATUS_LOAD.total) * 100
      " :active="FILES__STATUS_LOAD.status != FILES__STATUS_LOAD.total"></v-progress-linear>
        </div>
        <v-row v-for="(file, index) in files" :key="index" style="dispalay: flex; padding-left: 7px; margin: -12px 0px"
          :style="index % 2 ? {} : { background: '#f7f7f7' }">
          <div style="display: flex; align-items: center">{{ file.NAME }}</div>
          <div style="margin-left: auto">
            <v-btn target="_blank" depressed class="white--text ma-3" color="#1980d2" style="width: 100px"
              @click="loadFile(file, 'excel')">EXCEL</v-btn>
            <v-btn @click="loadFile(file, 'pdf')" target="_blank" depressed class="white--text ma-3" color="#19d279"
              style="width: 100px">PDF</v-btn>
            <v-btn @click="loadFile(file, 'onec')" target="_blank" depressed class="white--text ma-3" color="#ff0040"
              style="width: 100px">1C</v-btn>
          </div>
        </v-row>
      </div>
    </div>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      stateLoad: {
        total: 0,
        status: 0,
      },
      status: 3,
      total: 10,
    };
  },
  computed: {
    ...mapGetters(["FILES", "FILES__STATUS_LOAD"]),
    files() {
      return [...this.FILES].reverse();
    },
  },
  methods: {
    ...mapActions(["FILES__CLOSE"]),
    closePage() {
      this.FILES__CLOSE();
    },
    base64toBlob(base64Data, contentType) {
      contentType = contentType || '';
      var sliceSize = 1024;
      var byteCharacters = atob(base64Data);
      var bytesLength = byteCharacters.length;
      var slicesCount = Math.ceil(bytesLength / sliceSize);
      var byteArrays = new Array(slicesCount);

      for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
        var begin = sliceIndex * sliceSize;
        var end = Math.min(begin + sliceSize, bytesLength);

        var bytes = new Array(end - begin);
        for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
          bytes[i] = byteCharacters[offset].charCodeAt(0);
        }
        byteArrays[sliceIndex] = new Uint8Array(bytes);
      }
      return new Blob(byteArrays, { type: contentType });
    },
    loadFile(item, type) {
      console.log(item, 'item')
      var a = document.createElement("a");
      let Base64 = item[type];
      if (type == "excel") {
        a.href =
          "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," +
          Base64;
        a.download = item.NAME;
      } else if (type == "onec") {
        a.href =
          "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," +
          Base64;
        a.download = item.NAME_1C;
      } else if (type == "pdf") {
        // const blob = this.base64toBlob(Base64, 'application/pdf');
        // a.href = URL.createObjectURL(blob);
        // a.href = "data:application/pdf;base64," + Base64;
        // a.download = item.NAME_PDF;

        const fullBase64 = item.pdf + item.pdf_1 + item.pdf_2 + item.pdf_3
        
        var fileUrl = "data:application/pdf;base64," + fullBase64;
        fetch(fileUrl)
          .then(response => response.blob())
          .then(blob => {
            var link = window.document.createElement("a");
            link.href = window.URL.createObjectURL(blob, { type: 'application/pdf' });
            link.download = item.NAME_PDF;
            document.body.appendChild(link);
            link.click();
            // document.body.removeChild(link);
          });
      }
      a.click();
    },
  },
};
</script>

<style scoped></style>