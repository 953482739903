import _ from 'lodash'

export default {
	state: {
		moreFields: {},
		calculateDate: {}
	},
	getters: {
		CALCULATE__MORE(state) {
			return state.moreFields
		},
		CALCULATE__VALUE(state) {
			return state.calculateDate
		}
	},
	mutations: {
		CALCULATE__MORE_CHANGE(state, val) {
			if (Object.keys(val).length != 0) {
				state.moreFields = val;
			} else {
				state.moreFields = {}
			}
		},
		CALCULATE__VALUE_CHANGE(state, val) {
			if (Object.keys(val).length != 0) {
				state.calculateDate = val;
			} else {
				state.calculateDate = {}
			}
		}

	},
	actions: {
		CALCULATE__MORE_SAVE({ commit }, value) {
			return new Promise((resolve) => {
				fetch(this.state.url + 'calculate/more/save', {
					method: 'post',
					body: JSON.stringify(value)
				}).then(() => {

					commit('CALCULATE__MORE_CHANGE', value.data)
					resolve();
				})
			})
		},
		CALCULATE__MORE_GET({ commit }, id) {
			return new Promise((resolve) => {
				let params = { id };
				fetch(this.state.url + 'calculate/more/get?' + new URLSearchParams(params), {
					method: 'get',
				}).then(async (res) => {
					let item = await res.json();
					commit('CALCULATE__MORE_CHANGE', item);
					resolve()
				})
			})
		},

		CALCULATE__VALUE_SAVE({ commit }, value) {
			return new Promise((resolve) => {
				fetch(this.state.url + 'calculate/value/save', {
					method: 'post',
					body: JSON.stringify(value)
				}).then(() => {
					commit('CALCULATE__MORE_CHANGE', value.data)
					resolve();
				})
			})
		},
		CALCULATE__VALUE_GET({ commit }, id) {
			return new Promise((resolve) => {
				let params = { id };
				fetch(this.state.url + 'calculate/value/get?' + new URLSearchParams(params), {
					method: 'get'
				}).then(async (res) => {
					let item = await res.json();
					commit('CALCULATE__VALUE_CHANGE', item);
					resolve()
				})
			})
		},


		async CALCULATE__CREATE({ dispatch, commit }, { calcValues, specialCase }) {
			dispatch('FILES__OPEN');
			let createOneSituation = (value) => {
				return new Promise((resolve) => {
					fetch(this.state.url + "leasing/create", {
						method: "post",
						body: JSON.stringify(value)
					}).then(async res => {
						console.log("PROMISE - RESULT", res);
						let item = await res.json();
						console.log(item);
						dispatch("FILES__ADD", item);
						resolve();
					});
				})
			}
			if (specialCase) {
				commit('FILE__LOAD_TOTAL_SET', 1);
				commit('FILE__SET_LOAD_STATUS', 0)
				createOneSituation(calcValues);
			} else {
				let changeFields = [
					"contractCurrency",
					"depreciation",
					"insurance.type",
					"involvementValues",
					"leaseTerm",
					"repaymentType"
				];
				let cartesianProductOf = function() {
					return _.reduce(arguments, function (a, b) {
						return _.flatten(_.map(a, function (x) {
							return _.map(b.value, function (y) {
								return x.concat([{ name: b.name, value: y }]);
							});
						}), true);
					}, [[]]);
				}
				let multiSituation = cartesianProductOf(...changeFields.map(el => ({ value: _.get(calcValues, el), name: el })));
				commit('FILE__LOAD_TOTAL_SET', multiSituation.length);
				commit('FILE__SET_LOAD_STATUS', 0)
				for (let situationFields of multiSituation) {
					let oneSituation = { ...calcValues };
					situationFields.forEach(fields => {
						_.set(oneSituation, fields.name, fields.value)
					})
					console.log('ONE SITUATION', oneSituation);
					await createOneSituation(oneSituation);
				}
			}
		}
	}
}