export default {
	state: {
	},
	getters: {
		REPAYMENT_TYPE__default(state, getters , rootState) {
			console.log('---', rootState.settings.repaymentType.default)
			return rootState.settings.repaymentType.default
		},
		REPAYMENT_TYPE__more(state, getters , rootState) {
			return rootState.settings.repaymentType.more.map(el => ({...el, type: 'more'}))
		}
	},
	mutations: {
		REPAYMENT_TYPE__CHANGE_DATA(state, data) {
			let tempRepaymentType = this.state.settings.repaymentType.default.find(el=> el.name == data.type);
			if (!tempRepaymentType) {
				if (!this.state.settings.repaymentType.more) {
					this.state.settings.repaymentType.more = [];
				}
				tempRepaymentType = this.state.settings.repaymentType.more.find(el=> el.name == data.type)
				if (!tempRepaymentType) {

					this.state.settings.repaymentType.more.push({name: data.type});
					tempRepaymentType = this.state.settings.repaymentType.more.find(el=> el.name == data.type)
				}
			}
			if (!tempRepaymentType.data) {
				tempRepaymentType.data = {};
			}
			tempRepaymentType.data[data.month] = data.data;


		}


	},
	actions: {

	}
}