export default {
	state: {
	},
	getters: {
		USER__ADMIN(state, getters, rootState) {
			return rootState.settings.user.admin
		},
		USER__MANAGER(state, getters, rootState) {
			return rootState.settings.user.manager
		},
		USER__CURENTY(state, getters, rootState) {
			return rootState.curentyUser
		}
	},
	mutations: {
		USER__ADD(state, item) {
			console.log('___ADD = ', item, this.state)
			this.state.settings.user[item.type].push({...item.value});
		},
		USER__DELETE(state, item) {
			console.log('___DELETE = ', item, this.state);
			let index = this.state.settings.user[item.type].findIndex(el => el.id == item.id);
			this.state.settings.user[item.type].splice(index, 1);

		},
		USER__CHANGE_CURENTY(state, user) {
			this.state.curentyUser = user;
		}
	},
	actions: {
		async USER__GET_CURENTY({commit}) {
			return new Promise((resolve) => {
				// eslint-disable-next-line
				BX24.callMethod('profile', {}, async (res) => {
					let user = await res.data();
					commit('USER__CHANGE_CURENTY', user)
					resolve();
				})
			})
		}
	}
}