export default {
	state: {
		attractivenessRate: [],
		attractivenessRateFind: {}
	},
	getters: {
		ATTRACTIVENESS_RATE(state) {
			return state.attractivenessRate.map((el) => { return { ...el.PROPERTY_VALUES, id: el.ID } })
		},
		ATTRACTIVENESS_RATE__ITEM(state) {
			return (id) => {
				return state.attractivenessRateFind[id]?.rate ? state.attractivenessRateFind[id]?.rate : "0";
			}
			// return state.attractivenessRate.map((el) => { return { ...el.PROPERTY_VALUES, id: el.ID } })
		}
	},
	mutations: {
		ATTRACTIVENESS_RATE__SAVE(state, value) {
			console.log(value);
			state.attractivenessRate = value;
			let result = {};
			state.attractivenessRate.forEach((item) => {
				result[item.ID] = { ...item.PROPERTY_VALUES, id: item.ID, link: item }
			})
			state.attractivenessRateFind = result;
			console.log(state.attractivenessRateFind, 'FIND');
		},
		ATTRACTIVENESS_RATE__ADD(state, value) {
			console.log('-=-=-==- ADD')
			let idLink = state.attractivenessRate.push({ ID: value.id, PROPERTY_VALUES: value.data });
			state.attractivenessRateFind[value.id] = { ...value.data, id: value.id, link: state.attractivenessRate[idLink] }
		},
		ATTRACTIVENESS_RATE__CHANGE(state, value) {

			// state.templates = { ID: value.id, PROPERTY_VALUES: value.data };
			let editElement = state.attractivenessRateFind[value.id].link.PROPERTY_VALUES;
			for (let key of Object.keys(editElement)) {
				editElement[key] = value.data[key];
			}
			state.attractivenessRate = [...state.attractivenessRate];
			state.attractivenessRateFind[value.id] = { ...value.data, id: value.id, link: editElement }
			console.log(state.attractivenessRate, state.attractivenessRateFind)
		},
		ATTRACTIVENESS_RATE__DELETE(state, id) {
			let idDelete = state.attractivenessRate.findIndex((el) => el.ID == id);
			if (!idDelete == -1) {
				console.log('ID DELETE NOT')
			}
			state.attractivenessRate.splice(idDelete, 1);
			delete state.attractivenessRateFind[id]
		}
	},
	actions: {
		ATTRACTIVENESS_RATE__GET({ commit }) {
			return new Promise((resolve) => {
				fetch(this.state.url + 'attractiveness/get', {
					method: 'get'
				}).then(async (res) => {
					console.log(' GET - = ', res);
					let json = await res.json();
					if (json != null) {
						commit('ATTRACTIVENESS_RATE__SAVE', json);
						resolve(true)
					}
				}).catch(() => {
					resolve(false);
				})
			})
		},
		ATTRACTIVENESS_RATE__ADD({ commit }, data) {
			return new Promise((resolve) => {
				fetch(this.state.url + 'attractiveness/add', {
					method: 'post',
					body: JSON.stringify(data)
				}).then(async (res) => {
					console.log(' GET - = ', res);
					let json = await res.json();
					if (json != null) {
						console.log('-=-=-=');
						commit('ATTRACTIVENESS_RATE__ADD', { id: json, data });
						resolve(true);
					}
				}).catch(() => {
					resolve(false);
				})
			})
		},
		ATTRACTIVENESS_RATE__CHANGE({ commit }, { data, id }) {
			return new Promise((resolve) => {
				fetch(this.state.url + 'attractiveness/change', {
					method: 'post',
					body: JSON.stringify({ data, ID: id })
				}).then(async (res) => {
					let json = await res.json();
					if (json) {
						commit('TEMPLATES__CHANGE', { data, id })
					}
				}).catch(() => {
					resolve(false);
				})
			})
		},
		ATTRACTIVENESS_RATE__DELETE({ commit }, id) {
			return new Promise((resolve) => {
				fetch(this.state.url + 'attractiveness/delete', {
					method: 'delete',
					body: JSON.stringify({ ID: id })
				}).then(async (res) => {
					console.log(' GET - = ', res);
					commit('ATTRACTIVENESS_RATE__DELETE', id)

				}).catch(() => {
					resolve(false);
				})
			})
		}
	}
}