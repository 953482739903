<template>
  <v-card style="padding: 14px;
    margin: 10px;" elevation="2">
    <div>
      <span
        style="display: block; margin-top: 15px; font-size: 1.2em; color: rgb(25 118 210); font-weight: 500"
      >Права доступа</span>
      <v-divider style="border-color: rgb(25 118 210); margin-bottom: 25px"></v-divider>
      <v-row>
        <v-col cols="3" style="display: flex">
          <span style="padding-top: 10px; padding-right: 10px;">Администраторы</span>
        </v-col>
        <v-col cols="9" style="display: flex; padding-left: 0;">
          <v-autocomplete
            label="Администраторы"
            v-model="userAdmin"
            :readonly="true"
            :items="USER__ADMIN"
            :input-name="name"
            :input-value="id"
            dense
            solo
            color="blue-grey lighten-2"
            item-text="name"
            item-value="id"
            multiple
            append-icon="mdi-plus"
            @click:append="addUser('admin')"
          >
            <template v-slot:selection="data">
              <v-chip
                style="margin: 2px;"
                close
                @click:close="deleteUser('admin', data.item)"
              >{{ data.item.name }}</v-chip>
            </template>
            <template v-slot:item="data">
              <template>
                <v-list-item-content>
                  <v-list-item-title v-html="data.item.name"></v-list-item-title>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3" style="display: flex">
          <span style="padding-top: 10px; padding-right: 10px;">Менеджеры</span>
        </v-col>
        <v-col cols="9" style="display: flex; padding-left: 0;">
          <v-autocomplete
            label="Менеджеры"
            v-model="userManager"
            :readonly="true"
            :items="USER__MANAGER"
            :input-name="name"
            :input-value="id"
            dense
            solo
            color="blue-grey lighten-2"
            item-text="name"
            item-value="id"
            multiple
            append-icon="mdi-plus"
            @click:append="addUser('manager')"
          >
            <template v-slot:selection="data">
              <v-chip
                style="margin: 2px;"
                close
                @click:close="deleteUser('manager', data.item)"
              >{{ data.item.name }}</v-chip>
            </template>
            <template v-slot:item="data">
              <template>
                <v-list-item-content>
                  <v-list-item-title v-html="data.item.name"></v-list-item-title>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row style="margin-bottom: 10px">
        <v-col cols="12" sm="3" md="3" style="display: flex"></v-col>
        <v-col cols="12" sm="4" md="4" style="display: flex">
          <span
            style="display: block; margin-top: 15px; font-size: 1.2em; color: rgb(25 118 210); font-weight: 500"
          >Курсы валют</span>
        </v-col>
        <v-col cols="12" sm="5" md="5" style="display: flex">
          <span
            style="display: block; margin-top: 15px; font-size: 1.2em; color: rgb(25 118 210); font-weight: 500"
          >Ставка привлекаемого кредита</span>
        </v-col>
      </v-row>
      <div>
        <v-row v-for="item in currency" :key="item.name" style="margin-top: 0">
          <v-col cols="12" sm="3" md="3" style="display: flex; padding-top: 0; padding: 0">
            <span
              style="display: block; margin-top: 3px; margin-left: 31px; font-size: 1.2em; color: rgb(25 118 210); font-weight: 500"
            >{{item.name}}</span>
          </v-col>
          <v-col cols="12" sm="4" md="4" style="display: flex; padding-top: 0; padding: 0">
            <v-text-field
              @change="(value) => changeCurrency('value', item, value)"
              :value="item.value"
              label="Ввести..."
              style=" margin-top: 0; margin-bottom: -11px; margin-right: 15px;"
              placeholder
              dense
              solo
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="5" md="5" style="display: flex; padding-top: 0; padding: 0">
            <v-autocomplete
              :value="item.rateId"
              @change="(value) => changeCurrency('rateId', item, value)"
              :items="ATTRACTIVENESS_RATE"
              item-text="name"
              item-value="id"
              style="margin-top: 0px; margin-left: 10px"
              dense
              chips
              small-chips
              label
              solo
            >
              <template v-slot:selection="data">{{ data.item.rate }}</template>
            </v-autocomplete>
            <!-- <v-text-field
            label="..."
            style=" margin-top: 11px; margin-bottom: -11px;"
            placeholder
            dense
            solo
            ></v-text-field>-->
            <v-btn
              v-if="item.type == 'more'"
              color="red"
              style="border-radius: 50%; width: 40px; height: 40px; min-width: auto; margin-top: 0px; margin-left: 15px;"
              text
              @click="deleteCurrency(item)"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <div v-else style="width: 55px"></div>
          </v-col>
        </v-row>
        <v-row class="margin-top: 0">
          <v-col cols="12" sm="3" md="3">
            <v-text-field
              label="Ввести..."
              style="width: 100px;"
              placeholder
              dense
              solo
              v-model="newNameCurrency"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="4" style="display: flex; padding-left: 0">
            <v-btn color="primary" @click="addCurrency">Добавить</v-btn>
          </v-col>
        </v-row>
      </div>

      <span
        style="display: block; margin-top: 15px; font-size: 1.2em; color: rgb(25 118 210); font-weight: 500"
      >Типы гашения</span>
      <v-divider style="border-color: rgb(25 118 210); margin-bottom: 25px"></v-divider>
      <v-data-table
        :headers="repaymentTypeHeaders"
        :items="repaymentType"
        :expanded.sync="expanded"
        item-key="name"
        show-expand
        class="elevation-1"
      >
        <template v-slot:expanded-item="repaymentType">
          <td :colspan="repaymentType.headers.length" style="padding: 8px">
            <v-data-table
              :headers="monthHeaders"
              :items="monthDefault"
              item-key="name"
              hide-default-footer
              hide-default-header
            >
              <template v-slot:item.name="{item}">
                <div
                  @click="openDialogRepayment({item, repaymentType})"
                  style="cursor: pointer;"
                >{{item.name}}</div>
              </template>
            </v-data-table>
          </td>
        </template>
      </v-data-table>
      <v-dialog v-model="dialogRepayment" persistent max-width="350px">
        <v-card>
          <v-card-title>
            <span class="text-h5">{{dialogRepaymentInfo.type}} - {{dialogRepaymentInfo.month}}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row
                v-for="i in dialogRepaymentInfo.month"
                :key="i + '-' + dialogRepaymentInfo.month"
                style="margin-bottom: -40px;"
              >
                <v-col cols="2" style="padding-left: 0">
                  <span
                    style="margin-top: 10px; display: block; font-size: 1.5em; color: rgb(25 118 210)"
                  >{{i}}</span>
                </v-col>
                <v-col cols="6">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        append-icon="mdi-percent-outline"
                        solo
                        dense
                        v-model="dialogRepaymentActive[i-1]"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <span>{{ sumDialogField(dialogRepaymentInfo.type, dialogRepaymentInfo.month) }}</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialogRepayment = false">Закрыть</v-btn>
            <v-btn color="blue darken-1" text @click="saveDialogRepayment">Сохранить</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <span
        style="display: block; margin-top: 15px; font-size: 1.2em; color: rgb(25 118 210); font-weight: 500"
      >Настройки выгрузки</span>
      <v-divider style="border-color: rgb(25 118 210); margin-bottom: 25px"></v-divider>
      <v-row>
        <v-col cols="3" style="display: flex">
          <span style="padding-top: 10px; padding-right: 10px;">Символов после запятой</span>
        </v-col>
        <v-col cols="9" style="display: flex; padding-left: 0;">
          <v-text-field dense solo color="blue-grey lighten-2" v-model="excelRound"></v-text-field>
        </v-col>
      </v-row>

      <v-snackbar v-model="error.onError" :multi-line="true">
        {{ error.message }}
        <template v-slot:action="{ attrs }">
          <v-btn color="red" text v-bind="attrs" @click="error.onError = false">Закрыть</v-btn>
        </template>
      </v-snackbar>
      <div style="display: flex">
        <v-btn
          style="margin-left: auto; margin-top: 15px"
          color="primary"
          @click="saveSetting"
        >Сохранить</v-btn>
      </div>
    </div>
  </v-card>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  data() {
    return {
      newNameCurrency: "",
      expanded: [],
      singleExpand: false,
      monthDefault: [
        {
          name: "6 месяцев",
          value: 6
        },
        {
          name: "12 месяцев",
          value: 12
        },
        {
          name: "18 месяцев",
          value: 18
        },
        {
          name: "24 месяцев",
          value: 24
        },
        {
          name: "30 месяцев",
          value: 30
        },
        {
          name: "36 месяцев",
          value: 36
        },
        {
          name: "48 месяцев",
          value: 48
        },
        {
          name: "60 месяцев",
          value: 60
        }
      ],
      monthHeaders: [
        {
          text: "Название",
          align: "start",
          sortable: false,
          value: "name"
        }
      ],
      repaymentTypeHeaders: [
        {
          text: "Название",
          align: "start",
          sortable: false,
          value: "name"
        }
      ],
      dialogRepayment: false,
      dialogRepaymentInfo: {
        item: { value: 0, name: "" },
        repaymentType: { item: { name: 0 } }
      },
      dialogRepaymentActive: [],
      error: {
        message: "",
        onError: false
      },
      errorMessage: {
        currencyNameHas: "Валюта с таким названием уже есть",
        currencyNameEmpty: "Введите название валюты",
        repayment: "Сумма не равно 100%"
      }
    };
  },
  computed: {
    ...mapGetters([
      "TEMPLATES",
      "ATTRACTIVENESS_RATE",
      "CURRENCY__default",
      "CURRENCY__more",
      "CURRENCY__BYN",
      "REPAYMENT_TYPE__default",
      "USER__ADMIN",
      "USER__MANAGER",
      "SETTINGS__EXCEL"
    ]),

    currency() {
      console.log(this.CURRENCY__default, this.CURRENCY__more);
      return [...this.CURRENCY__default, ...this.CURRENCY__more];
    },
    repaymentType() {
      return [...this.REPAYMENT_TYPE__default];
    },
    userAdmin() {
      return this.USER__ADMIN.map(el => el.id);
    },
    userManager() {
      return this.USER__MANAGER.map(el => el.id);
    },
    excelRound: {
      get() {

        return this.SETTINGS__EXCEL.round;
      },
      set(value) {
        this.SETTING__EXCEL_CHANGE_ROUND(value)
      }
    }
  },
  methods: {
    ...mapActions(["SETTINGS_save"]),
    ...mapMutations([
      "CURRENCY_addMore",
      "REPAYMENT_TYPE__CHANGE_DATA",
      "CURRENCY__CHANGE",
      "CURRENCY__DELETE",
      "USER__ADD",
      "USER__DELETE",
      "SETTING__EXCEL_CHANGE_ROUND"
    ]),
    saveSetting() {
      this.SETTINGS_save();
    },
    addCurrency() {
      if (this.newNameCurrency != "") {
        if (
          ![
            ...this.CURRENCY__default,
            ...this.CURRENCY__more,
            ...this.CURRENCY__BYN
          ].find(el => el.name == this.newNameCurrency)
        ) {
          this.CURRENCY_addMore(this.newNameCurrency);
          this.newNameCurrency = "";
        } else {
          this.error.message = this.errorMessage.currencyNameHas;
          this.error.onError = true;
        }
      } else {
        this.error.message = this.errorMessage.currencyNameEmpty;
        this.error.onError = true;
      }
    },
    addUser(type) {
      let vm = this;
      // eslint-disable-next-line
      BX24.selectUsers(function(users) {
        console.log(users);
        users.forEach(item => {
          let check;
          console.log("---=", vm.userManager, vm.userAdmin);
          if (type == "manager") {
            check = vm.userManager.includes(item.id);
          } else {
            check = vm.userAdmin.includes(item.id);
          }
          if (!check) {
            vm.USER__ADD({ type, value: item });
          }
        });
      });
    },
    deleteUser(type, value) {
      console.log(type, value);
      this.USER__DELETE({ type, value });
    },
    changeCurrency(type, item, value) {
      console.log("--=", type, item, value);
      this.CURRENCY__CHANGE({ type, more: item.type, name: item.name, value });
    },
    deleteCurrency(item) {
      this.CURRENCY__DELETE(item);
    },
    openDialogRepayment(info) {
      console.log(info);
      this.dialogRepayment = true;
      this.dialogRepaymentInfo = {
        ...info,
        type: info.repaymentType.item.name,
        month: info.item.value,
        data: info.repaymentType.item.data
      };
      let { type, month, data } = { ...this.dialogRepaymentInfo };
      console.log(type, month, data, this.dialogRepaymentInfo);
      this.dialogRepaymentActive = [...Array(month)];
      let onData = false;
      let dataV = [];
      if (data) {
        dataV = data[month];
        console.log("data", dataV);
        if (dataV) {
          onData = true;
        }
      }

      for (let i = 0; i < month; i++) {
        console.log("-=");
        this.dialogRepaymentActive[i] = onData ? dataV[i] : 0;
      }
    },
    saveDialogRepayment() {
      let sum = 0;
      for (let i = 0; i < this.dialogRepaymentInfo.item.value; i++) {
        sum += Number(this.dialogRepaymentActive[i]);
      }
      console.log(sum, "==");
      if (sum == 100) {
        console.log("+");
        console.log(this.repaymentType);
        this.REPAYMENT_TYPE__CHANGE_DATA({
          type: this.dialogRepaymentInfo.type,
          month: this.dialogRepaymentInfo.month,
          data: this.dialogRepaymentActive
        });
        this.dialogRepayment = false;
      } else {
        this.error.message = this.errorMessage.repayment;
        this.error.onError = true;
      }
    },
    sumDialogField() {
      console.log(this.dialogRepaymentActive);
      return this.dialogRepaymentActive.reduce((partial_sum, a) => {
        return (Number(partial_sum) + Number(a)).toFixed(2);
      });
    }
  }
};
</script>

<style scoped>
</style>