<template>
  <div>
    <v-tabs v-model="tab" align-with-title>
      <v-tabs-slider color="blue"></v-tabs-slider>
      <v-tab v-for="item in menu" :href="`#${item.value}`" :key="item.value">{{ item.label }}</v-tab>
    </v-tabs>
    <v-tabs-items :value="tab">
      <v-tab-item v-if="user == 'admin' || user == 'manager'" :value="menu[0].value">
        <calculator  v-show="!FILES__PAGE_STATUS"/>
        <load-files-page v-show="FILES__PAGE_STATUS"/>
      </v-tab-item>
      <v-tab-item v-if="user == 'admin'" :value="menu[1].value">
        <settings/>
      </v-tab-item>
      <v-tab-item v-if="user == 'admin'" :value="menu[2].value">
        <catalog/>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import Calculator from "./tabs/Calculator.vue";
import Settings from "./tabs/Settings.vue";
import Catalog from "./tabs/Catalog.vue";
import LoadFilesPage from "./tabs/LoadFilesPage.vue"
import {mapGetters} from 'vuex';
export default {
  data() {
    return {
      menu: [
      ],
      menuAdmin: [
        {
          label: "Калькулятор",
          value: "calc"
        },
        {
          label: "Настройки",
          value: "settings"
        },
        {
          label: "Справочник",
          value: "catalog"
        }
      ],
      menuManager: [
        {
          label: "Калькулятор",
          value: "calc"
        }
      ],
      user: 'none',
      text:
        "",
      tab: "calc"
    };
  },
  computed: {
    ...mapGetters([
      'FILES__PAGE_STATUS',
      'USER__MANAGER',
      'USER__ADMIN',
      'USER__CURENTY',
    ])
  },
  components: {
    Calculator,
    Settings,
    Catalog,
    LoadFilesPage
  },
  mounted() {
    if ((this.USER__CURENTY.ADMIN == true) || (this.USER__ADMIN.find(el => el.id == this.USER__CURENTY.ID))) {
      this.user = 'admin'
      this.menu = this.menuAdmin
    } else if (this.USER__MANAGER.find(el => el.id == this.USER__CURENTY.ID)) {
      this.user = 'manager'
      this.menu = this.menuManager
    }
  }
};
</script>

<style scoped>
</style>