import currency from './currency';
import repaymentType from './repaymentType';
import user from './user'
export default {
	state: {
		currency: {
			BYN: [
				{
					name: "BYN",
					value: 1,
					rateId: null
				}
			],
			default: [
				{
					name: "USD",
					value: 0,
					rateId: null
				},
				{
					name: "EUR",
					value: 0,
					rateId: null
				},
				{
					name: "RUB",
					value: 0,
					rateId: null
				}
			],
			more: [
			]
		},
		repaymentType: {
			default: [
				{
					name: 'Ускоренный'
				},
				{
					name: 'Медленный'
				}
			],
			more: [

			]
		},
		user: {
		},
		excel: {
			round: 2
		}
	},
	getters: {
		SETTINGS(state) {
			return state.SETTINGS
		},
		SETTINGS__REPEYMENT_TYPE(state) {
			return state.repaymentType.default
		},
		SETTINGS__EXCEL(state) {
			return state.excel
		}
	},
	mutations: {
		SETTINGS_update(state, settings) {
			for (let i in settings) {
				state[i] = settings[i]
				if (i == "currency") {
					if (!settings.currency.more) {
						settings.currency.more = [];
					}
				}
			}
		},
		SETTINGS_comma_to_dot(state) {
			let data = { ...state };
			let commaToDot = (value) => {
				let regexp = /,/gi;
				return value ? String(value).replace(regexp, '.') : value;
			}
			Object.values(data.currency).forEach(elT => { console.log(elT, '++-+'); elT.forEach(el => el.value = commaToDot(el.value)) });
			data.repaymentType.default.forEach(
				elT => {
					if (elT.data) {
						Object.values(elT.data).forEach(
							elM => {
								if (Array.isArray(elM)) {
									elM.forEach(
										el => {
											return el = commaToDot( el );
										}
									)
								}
							}
						)
					}
				}
			);
		},
		SETTING__EXCEL_CHANGE_ROUND(state, value) {
			state.excel.round = value;
		}

	},
	actions: {
		SETTINGS_save({ state, commit }) {

			commit('SETTINGS_comma_to_dot');
			fetch(this.state.url + 'settings/save', {
				method: 'post',
				body: JSON.stringify(state)
			}).then((res) => {
				console.log('SAVE', res)
			})
		},
		SETTINGS_get({ commit }) {
			return new Promise((resolve) => {
				fetch(this.state.url + 'settings/get', {
					method: 'get'
				}).then(async (res) => {
					let item = await res.json();
					if (item.currency != null) {
						commit('SETTINGS_update', { currency: item.currency })
					} else {
						commit('SETTINGS_update', {
							currency: {
								BYN: [
									{
										name: "BYN",
										value: 1
									}
								],
								default: [
									{
										name: "USD",
										value: 0
									},
									{
										name: "EUR",
										value: 0
									},
									{
										name: "RUB",
										value: 0
									}
								],
								more: [
								]
							},
						})
					}

					if (item.repaymentType != null) {
						commit('SETTINGS_update', { repaymentType: item.repaymentType })
					} else {
						commit('SETTINGS_update', {
							repaymentType: {
								default: [
									{
										name: 'Ускоренный'
									},
									{
										name: 'Медленный'
									}
								],
								more: [

								]
							}
						})
					}
					if (item.user != null) {
						let user = {
							admin: item.user.admin ? item.user.admin : [],
							manager: item.user.manager ? item.user.manager : []
						}
						commit('SETTINGS_update', { user: user })
					} else {
						commit('SETTINGS_update', {
							user: {
								admin: [],
								manager: []
							}
						})
					}
					if (item.excel != null) {
						commit('SETTINGS_update', {
							excel: item.excel
						})
					}
					resolve();
				})
			})
		}

	},
	modules: {
		currency,
		repaymentType,
		user
	}
}