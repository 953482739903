<template>
  <v-app>
    <v-main>
      <v-skeleton-loader v-if="loader" type="image"></v-skeleton-loader>
      <Main v-if="!loader" />
    </v-main>
  </v-app>
</template>

<script>
import Main from "./components/Main";
import { mapActions } from "vuex";
export default {
  name: "App",

  components: {
    Main
  },

  data: () => ({
    loader: true
    //
  }),
  methods: {
    ...mapActions([
      "SETTINGS_get",
      "TEMPLATES__GET",
      "ATTRACTIVENESS_RATE__GET",
      "SPECIAL_CASE__GET",
      "USER__GET_CURENTY",
      "FIT_WINDOW"
    ]),
    fixWindow() {
      let body = document.body;
      // Конфигурация observer (за какими изменениями наблюдать)
      const config = {
        attributes: true,
        childList: true,
        subtree: true
      };

      const callback = () => {
        this.FIT_WINDOW({width: body.offsetWidth, height: body.offsetHeight})
      };

      const observer = new MutationObserver(callback);

      observer.observe(body, config);

    }
  },
  async mounted() {
    let masPromise = [
      this.TEMPLATES__GET(), 
      this.ATTRACTIVENESS_RATE__GET(),
      this.SETTINGS_get(),
      this.USER__GET_CURENTY(),
      this.SPECIAL_CASE__GET()
      ]
    await Promise.all(masPromise);
    // await this.TEMPLATES__GET();
    // await this.ATTRACTIVENESS_RATE__GET();
    // await this.SETTINGS_get();
    // await this.SPECIAL_CASE__GET();
    this.loader = false;
    this.fixWindow();
  }
};
</script>
