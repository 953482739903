export default {
	state: {
	},
	getters: {
		CURRENCY__BYN(state, getters, rootState) {
			console.log('', rootState)
			return rootState.settings.currency.BYN
		},
		CURRENCY__default(state, getters, rootState) {
			return rootState.settings.currency.default
		},
		CURRENCY__more(state, getters, rootState) {
			return rootState.settings.currency.more.map(el => ({ ...el, type: 'more' }))
		}
	},
	mutations: {
		CURRENCY_addMore(state, val) {
			console.log(this, '--=', state);
			if (this.state.settings.currency.more.length == 0) {
				this.state.settings.currency.more = [{
					name: val,
					value: 0
				}]
				this.state.settings.currency = {...this.state.settings.currency}
			} else {
				this.state.settings.currency.more.push({
					name: val,
					value: 0
				})
			}
		},
		CURRENCY__CHANGE(state, val) {
			let changeCurrency = {}
			if (val.more) {
				changeCurrency = this.state.settings.currency.more.find(el => el.name == val.name)
			} else {
				changeCurrency = this.state.settings.currency.default.find(el => el.name == val.name)
			}
			console.log(changeCurrency);
			changeCurrency[val.type] = val.value;
		},
		CURRENCY__DELETE(state, val) {
			console.log(val, this.state.settings.currency)
			let id = this.state.settings.currency.more.findIndex(el => el.name == val.name);
			if (id == '-1') {
				console.log('-=-=-=-= ERROR DELETE',)
			} else {
				this.state.settings.currency.more.splice(id, 1)
			}


		}


	},
	actions: {

	}
}